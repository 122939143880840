import { createAsyncThunk } from "@reduxjs/toolkit";
import { EVENT } from "../../config/constants/api";
import { Get } from "../../config/api/get";

//admin login api start here
const getAllEvents = createAsyncThunk("event/getAllEvents", async (data) => {
  try {
    const { otherData, token } = data;
    const response = await Get(EVENT.getAllEvents, token, {
      page: otherData?.page,
      limit: otherData?.limit,
      category: otherData?.category,
      date: otherData?.date,
      minPrice: otherData?.minPrice,
      maxPrice: otherData?.maxPrice,
      log: otherData?.longitude,
      lat: otherData?.latitude,
      keyword : otherData?.keyword || undefined,
      from : otherData?.from, 
      to: otherData?.to,
      followedEvents : otherData?.followedEvents,
      
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
});

export { getAllEvents };
