import React, { useEffect, useState } from "react";
import { Col, Row, Button, Input, Rate, Spin, Image } from "antd";
import { Get } from "../../config/api/get";
import { REVIEWS, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";

// const reviews = [
//   {
//     id: 1,
//     reviewsProfile: (
//       <img src="" alt="" className="img-fluid" style={{ width: "100%" }} />
//     ),
//     reviewName: "James Anderson",
//     rating: 5,
//     reviewDiscretion:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
//   },
//   {
//     id: 2,
//     reviewsProfile: (
//       <img src="" alt="" className="img-fluid" style={{ width: "100%" }} />
//     ),
//     rating: 4,
//     reviewName: "James Anderson",
//     reviewDiscretion:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
//   },
// ];
let ready = false

function Reviews(props) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rate, setRate] = useState(0);
  const [visibleReviews, setVisibleReviews] = useState(10);
  const [message, setMessage] = useState("");
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDcs: 0,
    totalPages: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  let eventId = props?.eventId;
  const token = useSelector((state) => state.user.userToken);
  const getEventReviews = async (page = 1) => {
    setLoading(true);
    Get(`${REVIEWS.getEventReviews}${eventId}`, token, {
      page,
      limit: paginationConfig.limit,
    })
      .then((response) => {
        if (response?.status) {
          setReviews((prevReviews) => [
            ...prevReviews,
            ...response?.data?.docs,
          ]);
          // setReviews();
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
          setHasMore(response?.data?.page < response?.data?.totalPages);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching event reviews ", err);
        setLoading(false);
      });
  };
  const handleRateChange = (e) => {
    setRate(e);
  };
  const handleLoadMore = () => {
    // setVisibleReviews((prev) => prev + 10);
    if (hasMore) {
      getEventReviews(paginationConfig.pageNumber + 1);
    }
  };
  useEffect(() => {
    if(ready){
      getEventReviews();
    }
    ready = true
  }, []);
  const handleReviewSubmit = async () => {
    if (rate === 0) {
      swal("Error", "Rating is Required", "error");
      return;
    }
    if (message === "") {
      swal("Error", "Message is Required", "error");
      return;
    }
    const data = {
      message,
      rating: rate,
      event: eventId,
    };
    Post(REVIEWS.addReview, data, token)
      .then((response) => {
        if (response?.status) {
          swal("Success!", response?.message, "success");
          setMessage("");
          setRate(0);
          getEventReviews();
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  return (
    <div className="">
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <h2
            className="f-50"
            style={{ textAlign: "center", padding: "40px 0" }}
          >
            Member Reviews
          </h2>
        </Col>
      </Row>
      {!loading ? (
        <Row justify="center">
          <Col lg={24} xs={23}>
            {Array.isArray(reviews) &&
              reviews.map((review, index) => (
                <div className="details-card">
                  <div key={index} className="review-box">
                    <div className="review-sugg">
                      <div className="for-media-object">
                        <div className="flex-shrink-0">
                          <Image
                            src={UPLOADS_URL + review?.user?.image}
                            alt=""
                            className="img-fluid"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <div className="star-18">
                            <Rate
                              allowHalf
                              defaultValue={review?.rating}
                              disabled
                            />
                          </div>
                          <h6>{review?.personName}</h6>
                        </div>
                      </div>
                    </div>
                    <p>{review?.message}</p>
                  </div>
                </div>
              ))}
            {/* {visibleReviews < reviews.length && (
              <button
                className="f-18-blue"
                onClick={loadMore}
                style={{
                  background: "none",
                  border: "none",
                  textDecoration: "underline",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Load More
              </button>
            )} */}
            {hasMore && (
              <button
                className="f-18-blue"
                onClick={handleLoadMore}
                style={{
                  background: "none",
                  border: "none",
                  textDecoration: "underline",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Load More Reviews
              </button>
            )}
            {!hasMore && (
              <p
                className="f-18-blue"
                style={{
                  textTransform: "capitalize",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                No More Reviews
              </p>
            )}
            {/* {visibleReviews >= reviews.length && (
              <p
                className="f-18-blue"
                style={{
                  textTransform: "capitalize",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                No More Reviews
              </p>
            )} */}
            <div
              className="mb-3"
              style={{
                marginTop: "50px",
                marginBottom: "50px",
                background: "white",
                padding: "20px",
                borderRadius: "6px",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="form-label">Write a Review</h6>
                <div className="rate">
                  <Rate allowHalf onChange={handleRateChange} />
                </div>
              </div>
              <Input.TextArea
                className="form-control web-textarea"
                rows={5}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
              />
            </div>
            <div className="text-center">
              <Button className="mainbtn" onClick={handleReviewSubmit}>
                POST COMMENT
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <Spin />
        </div>
      )}
    </div>
  );
}

export default Reviews;
