import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Menu,
  Image,
  Button,
  Popover,
  Drawer,
  Input,
  Space,
  Switch,
} from "antd";
import { MenuOutlined, UserOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { RiMapPin2Fill } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import WOW from "wowjs";
import "animate.css";
import { useSelector, useDispatch } from "react-redux";
import { removeUser, setMode } from "../../redux/slice/authSlice";
import { setKeyword } from "../../redux/slice/keywordSlice";
import { setLocation } from "../../redux/slice/locationSlice";
import { getAllEvents } from "../../redux/thunk/eventSlice";
import axios from "axios";
import { UPLOADS_URL } from "../../config/constants/api";
// import axios from "axios";

const { Search } = Input;

const items = [
  {
    // label: "a",
    key: "/",
  },
  // {
  //   label: "About us",
  //   key: "/about",
  // },
  {
    label: "Events",
    key: "/events",
  },
  // {
  //   label: "Blog",
  //   key: "/blog",
  // },
  {
    label: "Help Center",
    key: "/contactus",
  },
  {
    label: "Organize",
    key: "/organize",
  },
];

function Header({ header }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [current, setCurrent] = useState("mail");
  const [open, setOpen] = useState(false);
  const keyword = useSelector((state) => state.keyword.keyword);
  const location = useSelector((state) => state.location.location);
  const savedLocation = useSelector((state) => state.location.savedLocation);
  const token = useSelector((state) => state.user.userToken);
  const user = useSelector((state) => state.user.userData);
  const onSearch = async (value) => {
    // const data = {
    //   token: token,
    //   otherData: {
    //     longitude: location?.longitude,
    //     latitude: location?.latitude,
    //     keyword: keyword || undefined,
    //   },
    // };
    // await dispatch(getAllEvents(data)).unwrap();
    navigate("/events", { state: { locationData: location } });
  };
  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 0, // default
      mobile: false, // default
      live: true, // default
    });
    wow.init();
  }, []);
  const handleLogout = () => {
    dispatch(removeUser());
    navigate("/login");
  };

  const content = (
    <div>
      {/* <Button className="profile_btn" onClick={() => navigate("/profile")}>
        Profile
      </Button> */}
      <Button className="profile_btn" onClick={() => navigate("/profile")}>
        View Dashboard
      </Button>
      <Button className="profile_btn" onClick={handleLogout}>
        logout
      </Button>
    </div>
  );
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const apiKey = "AIzaSyBXqV9bSEkfm5Wh7OQMj37V-n3F4AiyE40";
  // const fetchLocation = async (lat, lon) => {
  //   const response = await axios.get(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
  //   );
  //   if (response?.data && response?.data?.results.length > 0) {
  //     const addressComponents = response.data.results[0].address_components;
  //     const city = addressComponents.find((component) =>
  //       component.types.includes("locality")
  //     )?.long_name;
  //     const state = addressComponents.find((component) =>
  //       component.types.includes("administrative_area_level_1")
  //     )?.long_name;
  //     if (city && state) {
  //       const locationData = { city, state, latitude: lat, longitude: lon };
  //       localStorage.setItem("locationData", JSON.stringify(locationData));
  //       setLocation(locationData);
  //     }
  //   }
  // };
  const onClick = (e) => {
    if (e.key === "/events") {
      dispatch(setLocation(null));
    } else {
      if (!location) {
        dispatch(setLocation(savedLocation));
      }
    }

    navigate(e.key);
    setCurrent(e.key);
  };

  const darkMode = useSelector((state) => state.user.isDarkMode);
  // const [isDarkMode, setIsDarkMode] = useState(true);
  
  // const onChange = (checked) => {
  //   setIsDarkMode(checked);
  //   document.body.classList.toggle('light-mode', !checked);
  //   document.body.classList.toggle('dark-mode', checked);
  //   console.log(`Switch to ${checked ? 'Dark Mode' : 'Light Mode'}`);
  // };
  
  const onChange = (event) => {
    dispatch(setMode());
    document.body.classList.toggle("light-mode", !darkMode);
    document.body.classList.toggle("dark-mode", darkMode);
    console.log(`Switched to ${darkMode ? "Dark Mode" : "Light Mode"}`);
  };

  return (
    <Row
      justify={"center"}
      className="navigation wow fadeInDown"
      data-wow-duration="2.5s"
      data-wow-delay="0.8s"
    >
      <Col xs={22} lg={20}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={3} sm={3} md={3} lg={2} xl={2}>
            <Link to="/">
              <Image
                className="logo"
                preview={false}
                src={ImageUrl("eventlogo.png")}
                alt="logo"
                onClick={() => {
                  if (!location) {
                    dispatch(setLocation(savedLocation));
                  }
                }}
              />
            </Link>
          </Col>
          <Col xs={19} sm={18} md={19} lg={11} xl={9} justify="center">
            <div className="searchbar-top">
              <Search
                value={keyword}
                placeholder="input search text"
                prefix={<CiSearch />}
                className="searchbar-top-first-searchfield"
                onChange={(e) => {
                  dispatch(setKeyword(e.target.value));
                }}
              />

              <Search
                value={
                  location?.city === null ||
                  location?.city === undefined ||
                  location?.state === null ||
                  location?.state === undefined
                    ? " "
                    : location?.city + " " + location?.state
                }
                placeholder="input search text"
                addonBefore={<RiMapPin2Fill />}
                onSearch={onSearch}
                className="searchbar-top-second-searchfield"
              />
            </div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={7} xl={8} justify="center">
            <Menu
              className="mainMenu"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </Col>
          <Col xs={2} sm={3} md={2} lg={4} xl={4} justify={"end"}>
            <Row style={{ width: "100%", padding: "0px 50px" }}></Row>

            <Row align={"middle"}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={0}
                style={{ textAlign: "right" }}
              >
                <MenuOutlined
                  style={{ fontSize: "25px" }}
                  onClick={() => showDrawer()}
                />
              </Col>
              <Col lg={24} md={0} sm={0} xs={0}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="switch">
                    <input
                      type="checkbox"
                      className="switch__input"
                      id="Switch"
                      defaultChecked={darkMode} // Set initial state based on isDarkMode
                      // onChange={onChange} // Trigger the updated onChange function
                      onChange={onChange}
                    />
                    <label className="switch__label" htmlFor="Switch">
                      <span className="switch__indicator" />
                      <span className="switch__decoration" />
                    </label>
                  </div>
                  {/* <Switch defaultChecked={isDarkMode} onChange={onChange} /> */}
                  {token && (
                    <Popover content={content}>
                      <Button className="profile">
                        <Image
                          className="logo"
                          preview={false}
                          src={UPLOADS_URL + user?.image}
                          alt="banner"
                        />{" "}
                        <span className="profileName">
                          {user?.firstName + " " + user?.lastName}
                        </span>
                        {/* <Image
                          className="logo"
                          preview={false}
                          src={ImageUrl("logo.png")}
                          alt="banner"
                        /> */}
                      </Button>
                    </Popover>
                  )}
                  {!token && (
                    <Link to="/login">
                      <Button
                        className="mainbtn"
                        onClick={() => navigate("/login")}
                      >
                        LOGIN <BsArrowUpRightCircleFill />
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>
            {/* */}

            <Drawer
              placement={"left"}
              closable={true}
              onClose={onClose}
              open={open}
              key={"left"}
              className="menu-drawer"
            >
              <Menu
                className="mainMenu "
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={items}
              />
              <div
                className="mobileprofile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}
              >
                <div className="switch">
                    <input
                      type="checkbox"
                      className="switch__input"
                      id="Switch2"
                      defaultChecked={darkMode} // Set initial state based on isDarkMode
                      onChange={onChange} // Trigger the updated onChange function
                    />
                    <label className="switch__label" htmlFor="Switch2">
                      <span className="switch__indicator" />
                      <span className="switch__decoration" />
                    </label>
                  </div>
                {/* <Switch defaultChecked={isDarkMode} onChange={onChange} /> */}
                <Popover content={content}>
                  <Button className="profile">
                    <Image
                      className="logo"
                      preview={false}
                      src={ImageUrl("eventlogo.png")}
                      style={{objectPosition:'center' , objectFit:'cover'}}
                      // src={ImageUrl("logo.png")}
                      alt="banner"
                    />
                  </Button>
                </Popover>
                {!token && (
                  <Link to="/login">
                    <Button
                      className="mainbtn"
                      onClick={() => navigate("/login")}
                    >
                      LOGIN <BsArrowUpRightCircleFill />
                    </Button>
                  </Link>
                )}
              </div>
            </Drawer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;
