import { Row, Col, Image, Button } from "antd";
import React from "react";

import { ImageUrl } from "../../config/helper";
import { FaCheckCircle } from "react-icons/fa";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();
  return (
    <div
      className="banner  wow fadeInDown"
      data-wow-duration="2.5s"
      data-wow-delay="0.8s"
    >
       
       <section className="ag-smoke-block">
    <div className="ag-smoke-1">
    <Image
                preview={false}
                src={ImageUrl("smoke-1.png")}
               
              />
      </div>
    <div className="ag-smoke-1 ag-smoke__delay-1">
      
    <Image
                preview={false}
                src={ImageUrl("smoke-1.png")}
               
              /></div>
    <div className="ag-smoke-1 ag-smoke__delay-2"><Image
                preview={false}
                src={ImageUrl("smoke-1.png")}
               
              /></div>
    <div className="ag-smoke-2"><Image
                preview={false}
                 src={ImageUrl("smoke-2.png")}
               
              /></div>
    <div className="ag-smoke-2 ag-smoke__delay-1"><Image
                preview={false}
                src={ImageUrl("smoke-2.png")}
               
              /></div>
    <div className="ag-smoke-2 ag-smoke__delay-2"><Image
                preview={false}
                src={ImageUrl("smoke-2.png")}
               
              /></div>
    <div className="ag-smoke-3"><Image
                preview={false}
                src={ImageUrl("smoke-3.png")}
               
              /></div>
    <div className="ag-smoke-3 ag-smoke__delay-1"><Image
                preview={false}
                src={ImageUrl("smoke-3.png")}
               
              /></div>
    <div className="ag-smoke-3 ag-smoke__delay-2"><Image
                preview={false}
                src={ImageUrl("smoke-3.png")}
               
              /></div>
    <div className="ag-smoke-4"><Image
                preview={false}
                src={ImageUrl("smoke-4.png")}
                
               
              /></div>
    <div className="ag-smoke-4 ag-smoke__delay-1"><Image
                preview={false}
                src={ImageUrl("smoke-4.png")}
               
              /></div>
    <div className="ag-smoke-4 ag-smoke__delay-2"><Image
                preview={false}
                src={ImageUrl("smoke-4.png")}
               
              /></div>
    <div className="ag-format-container">

    </div>
  </section>
      <Row justify="center">
        <Col xs={22} lg={20}>
          <Row align="middle">
            <Col xs={24} md={12}>
              <h3>
                Book your
                <br />
                tickets
              </h3>
              <h4>For events!</h4>
              <p>
                Welcome to Event Mela, your ultimate destination for discovering
                and enjoying events with ease. Our platform empowers organizers
                to create and manage their events seamlessly while providing
                users with a simple and efficient way to purchase tickets in
                just one click.
           </p>
           {/* <p>
                At Event Mela, we are committed to making event management and
                attendance hassle-free. Our user-friendly interface ensures a
                smooth experience for both organizers and attendees, making
                every event memorable and accessible. Join us and be part of the
                Event Mela community, where every event is just a click away.
              </p> */}
              <ul>
                <li>
                  <FaCheckCircle /> Your ticket to live entertainment!
                </li>
                <li>
                  <FaCheckCircle /> Safe, Secure, Reliable Ticketing.
                </li>
              </ul>
              <Button
                className="mainbtn" style={{position:"relative", zIndex:"5"}}
                onClick={() => {
                  navigate("/events");
                }}
              >
                Buy Ticket Now <BsArrowUpRightCircleFill />
              </Button>
            </Col>
            <Col xs={24} md={12} className="for-main-img">
              <Image
                preview={false}
                src={ImageUrl("banner-man.png")}
                alt="Banner"
                className="man-pic"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Banner;
