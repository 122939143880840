import React from 'react'
import { Col, Row, Card, Image, Button } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { FaLocationDot } from "react-icons/fa6";
import { TbTicket } from "react-icons/tb";
import { BsFillTicketPerforatedFill } from "react-icons/bs";
import { UPLOADS_URL } from '../../config/constants/api';

function EventCard({item}) {
  return (
    <div>
      <Link to={`/event-details/${item._id}`}>
    <Card
        className="blogCard onlyeventblogcard wow fadeInRight" data-wow-duration="2.5s" data-wow-delay="0.8s"
        title={
          <>
            <Image preview={false} src={UPLOADS_URL + item?.image} />
           
          </>
        }
        bordered={false}
      >
        <h4><span><FaLocationDot /> {item?.location?.address}</span> <span><TbTicket /></span></h4>
         <h3>{item?.title}</h3>
         {/* <h5><BsFillTicketPerforatedFill /> {item.rating}</h5> */}
        
        {/* <h6>{item.price}</h6> */}
        <span className='price'>${item.price}</span>
      </Card>
      </Link>
    </div>
  )
}

export default EventCard