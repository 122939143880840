import React from 'react'
import { Col, Row, Card, Image, Button,  Rate } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { FaLocationDot } from "react-icons/fa6";
import { TbTicket } from "react-icons/tb";
import { UPLOADS_URL } from '../../config/constants/api';

function OrganizerCard() {
  return (
    <div>
    <Link to={`/profileOrganizerDetails`}>
    <Card
        className="blogCard wow fadeInRight" data-wow-duration="2.5s" data-wow-delay="0.8s"
        title={
          <>
            <Image preview={false} src="" />
           
          </>
        }
        bordered={false}
      >
        <h3>Company Name</h3>
        <h4><span><Rate allowHalf defaultValue={2.5} disabled /> 5.0</span> </h4>
         
      </Card>
      </Link>
    </div>
  )
}

export default OrganizerCard