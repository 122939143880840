import React from "react";
import { Layout, Col } from "antd";
import FilterAndCards from "../../views/events/filterAndcards";



function ServiceProvidersPrivateEvent() {
  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
    <FilterAndCards/>
  
    

    </Layout>
  );
}

export default ServiceProvidersPrivateEvent;
