import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    keyword : ''
}

export const keywordSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      setKeyword: (state, { payload }) => {
       state.keyword = payload
      },
    },
  });
  
  // Action creators are generated for each case reducer function
  export const { setKeyword } = keywordSlice.actions
  
  export default keywordSlice.reducer