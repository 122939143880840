import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './slice/authSlice';
import keywordReducer from './slice/keywordSlice';
import locationReducer from './slice/locationSlice';
import {eventReducers} from './slice/eventSlice';

const rootReducer = combineReducers({
  user: userReducer,
  keyword: keywordReducer,
  event: eventReducers,
  location : locationReducer
})

const persistConfig = {
  key: 'ticket_booking_user',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer
})

export const persistor = persistStore(store)