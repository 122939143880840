import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Image, Spin } from "antd";
import { ImageUrl } from "../../config/helper";
import { Get } from "../../config/api/get";
import { EVENT, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import { IoLocationSharp } from "react-icons/io5";

const responsive = {
  380: { items: 1 },
  568: { items: 1 },
  1024: { items: 2 },
  1200: { items: 3 },
  1400: { items: 3 },
  1500: { items: 3 },
};

function Trending() {
  const [trendingEvents, setTrendingEvents] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  useEffect(() => {
    getTrendingEvents();
  }, []);
  const getTrendingEvents = () => {
    setLoading(true);
    Get(EVENT.getTrendingEvents, token)
      .then((response) => {
        if (response?.status) {
          setTrendingEvents(response?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching trending Events");
        setLoading(false);
      });
  };
  const items = useMemo(() => {
    if (!trendingEvents) return [];
    return (
      Array.isArray(trendingEvents) &&
      trendingEvents.length &&
      trendingEvents.map((event, index) => (
        <div className="item" key={event?._id}>
          <div
            className="trend wow fadeInRight"
            data-wow-duration="2.5s"
            data-wow-delay="0.8s"
          >
            <h4>{event?.title} </h4>
            <div className="portfolioCard">
              <div className="content">
                <div className="content-overlay"></div>

                <Image
                  preview={false}
                  src={`${UPLOADS_URL}${event?.image}`}
                  className="content-image"
                />
                <div className="content-details fadeIn-top">
                  <h5>
                    <span>{event?.title}</span>{" "}
                  </h5>
                  <p>
                    {event?.description?.length > 150
                      ? event?.description?.slice(0, 150)
                      : event?.description}
                  </p>
                  <h5 style={{fontSize:'16px'}}>
                    <IoLocationSharp /> <span>{event?.location?.address}</span>{" "}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  }, [trendingEvents]);

  return (
    <div
      className="trending  wow fadeInDown"
      data-wow-duration="2.5s"
      data-wow-delay="0.8s"
    >
      <Row justify={"center"}>
        {!loading ? (
          <Col xs={22} lg={20}>
            <h3>Sit back while we create the experience of a lifetime</h3>
            <h2>TRENDING EVENTS</h2>
            <AliceCarousel
              mouseTracking
              items={items ?? []}
              responsive={responsive}
              disableButtonsControls
              disableDotsControls
              controlsStrategy="alternate"
              paddingLeft={0}
              paddingRight={0}
              infinite
              autoPlay
              autoPlayInterval={2000}
              animationDuration={1500}
            />
          </Col>
        ) : (
          <Row
            style={{
              width: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Col
              lg={19}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "150px 0px",
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
}

export default React.memo(Trending);
