import React, { useEffect, useState } from "react";
import { Col, Row, Drawer, Image, Rate, Table } from "antd";
import { MdMenu } from "react-icons/md";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { FaSquareSnapchat, FaSquareXTwitter } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileSideBar from "../../../components/profileSidebar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { EVENT, UPLOADS_URL, USERS } from "../../../config/constants/api";
import { FaEye } from "react-icons/fa";
import { Get } from "../../../config/api/get";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { extractTime } from "../../../config/helpers";
import { Post } from "../../../config/api/post";
import swal from "sweetalert";

function ProfileOrganizerDetails() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const userData = useSelector((state) => state.user.userData);
  const organization = location?.state?.organization;
  const [isFollowing, setIsFollowing] = useState(false);

  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const formatTime = (time24) => {
    if (!time24) return "";

    const [hour, minute, second] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();
    date.setHours(hour, minute, second);

    // Check for invalid time values
    if (isNaN(date.getTime())) {
      console.error("Invalid time value:", time24);
      return "";
    }

    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleTabChange = (index) => {
    if (index === 0) {
      getOrganizationEvents(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        "UPCOMING"
      );
    } else {
      getOrganizationEvents(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        "COMPLETED"
      );
    }
    setTabIndex(index);
  };

  const [typeState, setTypeState] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = events
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (!organization) {
      navigate("/profileOrganizers");
    }
    if (
      organization?.followers &&
      organization?.followers?.includes(userData?._id)
    ) {
      setIsFollowing(true);
    }
  }, []);
  const getOrganizationEvents = async (
    pageNumber,
    pageSize,
    status,
    keyword
  ) => {
    Get(`${EVENT.getOrganizationEvents}${organization?._id}`, token, {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      status: status ? status : "UPCOMING",
      keyword,
    })
      .then((response) => {
        if (response?.status) {
          setEvents(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  const handleFollowUnfollow = async () => {
    Post(
      `${isFollowing ? USERS.unfollowOrganization : USERS.followOrganization}${
        organization?._id
      }`,
      {},
      token
    )
      .then((response) => {
        if (response?.status) {
          setIsFollowing((prevStatus) => !prevStatus);
          swal("Success", response?.message, "success");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message
          ? err?.response?.data?.message
          : err?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  useEffect(() => {
    getOrganizationEvents();
  }, []);
  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Event Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (item) => (
        <Image
          preview={false}
          src={UPLOADS_URL + item}
          width={"48px"}
          height={"48px"}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (item) => <span>{dayjs(item).format("M/D/YYYY")}</span>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (item) => <span>{formatTime(item)}</span>,
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (item) => (
        <FaEye
          style={{ fontSize: "16px", color: "#999999", cursor: "pointer" }}
          onClick={() =>
            navigate(`/event-details/${item}`, {
              state: { showBuyButton: tabIndex == 1 ? false : true },
            })
          }
          size={"20px"}
        />
      ),
    },
  ];

  return (
    <div className="shop-page contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={22} md={22}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <h5 className="text-center">Organizer - Details</h5>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={22} lg={22}>
                  <div className="my-4">
                    <Row gutter={[16, 16]}>
                      <Col xs={0} md={10} lg={7} xl={6}>
                        <div className="left-div">
                          <ProfileSideBar />
                        </div>
                      </Col>

                      <Col xs={24} md={0} lg={0} xl={0}>
                        <>
                          <MdMenu
                            style={{ fontSize: 26, color: "#fff" }}
                            onClick={showDrawer}
                          />
                          <Drawer
                            placement="right"
                            onClose={onClose}
                            open={open}
                            className="fliterMenu"
                          >
                            <ProfileSideBar />
                          </Drawer>
                        </>
                      </Col>

                      <Col xs={24} md={14} lg={17} xl={18}>
                        <div className="blogCard text-left">
                          <Row gutter={[16, 16]}>
                            <Col xs={24} md={8} lg={6} xl={5}>
                              <Image
                                preview={false}
                                src={UPLOADS_URL + organization?.image}
                              />
                            </Col>
                            <Col xs={24} md={16} lg={18} xl={19}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <h3>
                                    {organization?.firstName +
                                      " " +
                                      organization?.lastName}
                                  </h3>
                                  {organization?.averageRating && (
                                    <h4>
                                      <span>
                                        <Rate
                                          allowHalf
                                          defaultValue={
                                            organization?.averageRating
                                          }
                                          disabled
                                        />{" "}
                                        {organization?.averageRating}
                                      </span>{" "}
                                    </h4>
                                  )}
                                </div>
                                <div>
                                  <button
                                    className="mainbtn"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleFollowUnfollow}
                                  >
                                    {isFollowing ? "Unfollow" : "Follow"}
                                  </button>
                                </div>
                              </div>
                              <p>{organization?.description}</p>
                              {/* <div className="socail-icon">
                                <h4>Company Social Media</h4>
                                <FaFacebookSquare />
                                <FaInstagramSquare />
                                <FaSquareXTwitter />
                                <FaSquareSnapchat />
                                <AiFillTikTok />
                              </div> */}
                            </Col>
                          </Row>
                        </div>
                        <Col xs={24} className="tabsorganization">
                          <Tabs
                            selectedIndex={tabIndex}
                            onSelect={(index) => {
                              handleTabChange(index);
                            }}
                          >
                            <TabList>
                              <Tab>
                                {" "}
                                <h4 className="">Upcoming Events</h4>
                              </Tab>
                              <Tab>
                                {" "}
                                <h4 className="">Past Events</h4>
                              </Tab>
                            </TabList>

                            <TabPanel>
                              <Col xs={24}>
                                <Table
                                  dataSource={events}
                                  columns={columns}
                                  pagination={{ pageSize: 10 }}
                                />
                                ;
                              </Col>
                            </TabPanel>
                            <TabPanel>
                              <Col xs={24}>
                                <Table
                                  dataSource={events}
                                  columns={columns}
                                  pagination={{ pageSize: 10 }}
                                />
                                ;
                              </Col>
                            </TabPanel>
                          </Tabs>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ProfileOrganizerDetails;
