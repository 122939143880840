import { useState, useEffect } from "react";

import {
  Col,
  Row,
  Button,
  Input,
  Form,
  Select,
  DatePicker,
  Upload,
  message,
  Drawer,
} from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// import DashbordSidebar from "../../../components/DashboardSidebar";
import "react-phone-number-input/style.css";
import swal from "sweetalert";
import { PROFILE, UPLOADS_URL } from "../../../config/constants/api";
import { Put } from "../../../config/api/put";
import moment from "moment";
import { addUser } from "../../../redux/slice/authSlice";
import { FaFacebookSquare, FaInstagramSquare, FaCamera   } from "react-icons/fa";
import { FaSquareSnapchat, FaSquareXTwitter, FaSquareThreads } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";
import { MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ProfileSideBar from "../../../components/profileSidebar";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    message.error("Invalid Uplaod, You can only upload image files!");
  }
  return isImage;
};
const DropzoneFiltercards = () => {
  const [loading, setLoading] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [form] = Form.useForm();
  const token = useSelector((state) => state.user.userToken);
  const userData = useSelector((state) => state.user.userData);
  useEffect(() => {
    form.setFieldsValue({
      FirstName: userData?.firstName,
      LastName: userData?.lastName,
      Email: userData?.email,
      Phone: userData?.phone,
      facebook: userData?.socialMedia?.fb,
      instagram: userData?.socialMedia?.insta,
      xtwitter: userData?.socialMedia?.xtwitter,
      snapChat: userData?.socialMedia?.snapChat,
      tiktok: userData?.socialMedia?.tiktok,
      thread: userData?.socialMedia?.thread,
    });
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = (values) => {
    const {
      FirstName,
      LastName,
      Phone,
      facebook,
      instagram,
      xtwitter,
      snapChat,
      tiktok,
      thread
    } = values;
    const formValuesChanged = () => {
      return (
        userData?.firstName !== FirstName ||
        userData?.lastName !== LastName ||
        userData?.phone !== Phone ||
        userData?.socialMedia?.fb !== facebook ||
        userData?.socialMedia?.insta !== instagram ||
        userData?.socialMedia?.xtwitter !== xtwitter ||
        userData?.socialMedia?.snapChat !== snapChat ||
        userData?.socialMedia?.tiktok !== tiktok ||
        userData?.socialMedia?.thread !== thread ||
        imageObject
      );
    };
    if (formValuesChanged()) {
      let data = new FormData();
      if (imageObject) {
        data.append("image", imageObject);
      }

      if (FirstName) {
        data.append("firstName", FirstName);
      }
      if (LastName) {
        data.append("lastName", LastName);
      }
      if (Phone) {
        data.append("phone", Phone);
      }
      data.append("socialMedia", JSON.stringify({
        fb : facebook,
        insta : instagram,
        xtwitter,
        snapChat,
        tiktok,
        thread
      }));

      Put(PROFILE.editProfile, token, data, {}, "multipart")
        .then((response) => {
          if (response.status) {
            dispatch(addUser({ user: response?.data, token: token }));
            form.resetFields();
            swal("Success", "Profile Updated Successfully", "success");
            navigate("/profile");
          }
        })
        .catch((err) => {
          console.log("Error", err);
          swal("Error", err?.response?.data?.message, "error");
        });
    } else {
      swal("System Alert", "No Changes Detected", "error");
      return;
    }
  };
  const handleChangepro = (info) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        setImageObject(
          info?.fileList[info?.fileList?.length - 1]?.originFileObj
        );
        setLoading(false);
        setImageUrl(url);
      }
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={22} md={22}>
          <div className="shop-page-main shop-page contentarea inner-page">
            <Row justify={"center"}>
              <Col xs={22} md={22}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    {/* <DashbordSidebar /> */}
                    <div className="">
                      <div className="profile-information-wrapper">
                        <h3 style={{ color: "#fff", marginBottom: "20px" }}>
                          Edit Profile
                        </h3>
                      </div>

                      <Row gutter={[16, 16]}>
                        <Col xs={0} md={10} lg={7} xl={6}>
                          <div className="left-div">
                            <ProfileSideBar />
                          </div>
                        </Col>

                        <Col xs={24} md={0} lg={0} xl={0}>
                          <>
                            <MdMenu
                              style={{ fontSize: 26, color: "#fff" }}
                              onClick={showDrawer}
                            />
                            <Drawer
                              placement="right"
                              onClose={onClose}
                              open={open}
                              className="fliterMenu"
                            >
                              <ProfileSideBar />
                            </Drawer>
                          </>
                        </Col>

                        <Col xs={24} md={14} lg={17} xl={18}>
                          <div className="logo-rectangle">
                            <div className="profile-info">
                              <Row>
                                <Col xs={24} md={14} lg={17} xl={18}>
                                  <div className="wrapper-group-100000185">
                                    <>
                                      <Upload
                                        name="image"
                                        showUploadList={false}
                                        style={{ position: "relative" }}
                                        onChange={handleChangepro}
                                        beforeUpload={beforeUpload}
                                      >
                                        {" "}
                                        <div
                                          style={{
                                            height: "300px",
                                            // border: "1px solid gray",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                            objectFit: "cover",

                                            borderRadius: "50%",
                                          }}
                                        >
                                          {imageUrl ? (
                                            <img
                                              src={imageUrl}
                                              alt="avatar"
                                              style={{
                                                width: "100%",
                                                maxHeight: "300px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={
                                                UPLOADS_URL + userData?.image
                                              }
                                              alt="avatar"
                                              style={{
                                                width: "100%",
                                                maxHeight: "300px",
                                                objectFit: "cover",
                                                objectPosition: "center",
                                                filter: "blur(1px)",
                                              }}
                                            />
                                          )}
                                          <FaCamera
                                            style={{
                                              position: "absolute",
                                              color: "rgb(0,127,202)",
                                              fontSize: "25px",
                                            }}
                                          />
                                        </div>{" "}
                                      </Upload>
                                    </>
                                  </div>
                                </Col>

                                <Col md={14} lg={19} xl={20}>
                                  <div className="">
                                    <div className="">
                                      <div className="edit-profile-info">
                                        <Form
                                          className="row g-3"
                                          name="basic"
                                          layout="vertical"
                                          initialValues={{
                                            remember: true,
                                          }}
                                          onFinish={onFinish}
                                          onFinishFailed={onFinishFailed}
                                          autoComplete="off"
                                          form={form}
                                        >
                                          <Row
                                            style={{ width: "100%" }}
                                            gutter={[16, 16]}
                                          >
                                            <Col lg={12} md={12} xs={24}>
                                              <Form.Item
                                                label="First Name"
                                                name="FirstName"
                                              >
                                                <Input
                                                  size="large"
                                                  className="web-input"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} xs={24}>
                                              <Form.Item
                                                label="Last Name"
                                                name="LastName"
                                              >
                                                <Input
                                                  size="large"
                                                  className="web-input"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} xs={24}>
                                              <Form.Item
                                                label="Phone Number"
                                                name="Phone"
                                              >
                                                <Input
                                                  size="large"
                                                  // placeholder={profileDetails?.phone}
                                                  className="web-input"
                                                  type="number"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} xs={24}>
                                              <Form.Item
                                                label="Email"
                                                name="Email"
                                              >
                                                <Input
                                                  size="large"
                                                  className="web-input"
                                                  disabled
                                                />
                                              </Form.Item>
                                            </Col>

                                            {/* <Col lg={24} md={12} xs={24}>
                                        <Form.Item
                                          label="Date Of Birth"
                                          name="DOB"
                                        >
                                          <DatePicker className="web-input" />
                                        </Form.Item>
                                      </Col> */}
                                            <Col xs={24}>
                                              <div className="socail-icon black-icon">
                                                <h4>My Social Media</h4>
                                                <Row alignItems={"center"}>
                                                  <Col xs={2}>
                                                    <FaFacebookSquare />
                                                  </Col>
                                                  <Col xs={22}>
                                                    <Form.Item name="facebook">
                                                      <Input
                                                        size="large"
                                                        className="web-input"
                                                        placeholder="Facebook"
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Row alignItems={"center"}>
                                                  <Col xs={2}>
                                                    <FaInstagramSquare />
                                                  </Col>
                                                  <Col xs={22}>
                                                    <Form.Item name="instagram">
                                                      <Input
                                                        size="large"
                                                        className="web-input"
                                                        placeholder="Instagram"
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Row alignItems={"center"}>
                                                  <Col xs={2}>
                                                    <FaSquareXTwitter />
                                                  </Col>
                                                  <Col xs={22}>
                                                    <Form.Item name="xtwitter">
                                                      <Input
                                                        size="large"
                                                        className="web-input"
                                                        placeholder="X Twitter"
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Row alignItems={"center"}>
                                                  <Col xs={2}>
                                                    <FaSquareSnapchat />
                                                  </Col>
                                                  <Col xs={22}>
                                                    <Form.Item name="snapChat">
                                                      <Input
                                                        size="large"
                                                        className="web-input"
                                                        placeholder="SnapChat"
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Row alignItems={"center"}>
                                                  <Col xs={2}>
                                                  <FaSquareThreads />
                                                  </Col>
                                                  <Col xs={22}>
                                                    <Form.Item name="thread">
                                                      <Input
                                                        size="large"
                                                        className="web-input"
                                                        placeholder="thread"
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                                <Row alignItems={"center"}>
                                                  <Col xs={2}>
                                                    <AiFillTikTok />
                                                  </Col>
                                                  <Col xs={22}>
                                                    <Form.Item name="tiktok">
                                                      <Input
                                                        size="large"
                                                        className="web-input"
                                                        placeholder="Tiktok"
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Col>
                                            <Col xs={24}>
                                              <div className="d-block">
                                                <div
                                                  className=""
                                                  style={{
                                                    textAlign: "center",
                                                    display: "flex",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  <Button
                                                    type=""
                                                    htmlType="submit"
                                                    className="mainbtn px-5"
                                                  >
                                                    Update
                                                  </Button>
                                                  <Button
                                                    type="button"
                                                    className="mainbtn"
                                                    onClick={() =>
                                                      navigate("/profile")
                                                    }
                                                  >
                                                    Cancel
                                                  </Button>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
