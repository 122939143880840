import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {},
  userToken: null,
  keyword: "",
  isDarkMode: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      // console.log("payload",payload)
      state.userData = payload.user;
      state.userToken = payload.token;
    },
    updateUserData: (state, { payload }) => {
      state.userData = payload.user.user;
    },
    removeUser: (state) => {
      state.userData = {};
      state.userToken = null;
      localStorage.clear();
    },
    addData: (state, { payload }) => {
      // console.log(payload)
      state[payload.name] = payload.value;
    },
    setKeyword: (state, { payload }) => {
      state.keyword = payload;
    },
    setMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addUser,
  removeUser,
  updateUserData,
  addData,
  setKeyword,
  setMode,
} = userSlice.actions;

export default userSlice.reducer;
