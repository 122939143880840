import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  Drawer,
  Table,
  Image,
  DatePicker,
  Popover,
} from "antd";
import { MdMenu } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import ProfileSideBar from "../../../components/profileSidebar";
import { FaEye, FaFilter, FaSearch } from "react-icons/fa";
import { BOOKING, UPLOADS_URL } from "../../../config/constants/api";
import { useSelector } from "react-redux";
import { Get } from "../../../config/api/get";
import dayjs from "dayjs";
import { extractTime } from "../../../config/helpers";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";

const ProfileTickets = () => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState(null);
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.userToken);
  const showDrawer = () => {
    setOpen(true);
  };

  const handleOpenChange = (newOpen) => {
    setOpenTwo(newOpen);
  };
  const [filter, setFilter] = useState({
    status: null,
    type: null,
    keyword: "",
    from: null,
    to: null,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const onClose = () => {
    setOpen(false);
  };
  const formatTime = (time24) => {
    if (!time24) return "";

    const [hour, minute, second] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();
    date.setHours(hour, minute, second);

    // Check for invalid time values
    if (isNaN(date.getTime())) {
      console.error("Invalid time value:", time24);
      return "";
    }

    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  const getMyBookings = (pageNumber, pageSize, from, to, keyword) => {
    setLoading(true);
    Get(BOOKING.getMyAllBookings, token, {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      status: "UPCOMING",
      from,
      to,
      keyword,
    })
      .then((response) => {
        if (response?.status) {
          setBookings(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  const handleSearch = useDebouncedCallback((value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
    getMyBookings(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      null,
      null,
      value
    );
  }, 1000);
  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      from: null,
      to: null,
    });
  };

  useEffect(() => {
    getMyBookings();
  }, []);
  const data = {
    status: filter.status,
    keyword: filter.keyword,
    from: filter.from,
    to: filter.to,
  };
  const handleApply = () => {
    let from;
    let to;

    if (data.from) {
      from = moment(filter?.from?.$d).format("YYYY-MM-DD");
    }
    if (data.to) {
      to = moment(filter?.to?.$d).format("YYYY-MM-DD");
    }
    if (from || to) {
      getMyBookings(
        paginationConfig.pageNumber,
        paginationConfig.limit,
        from,
        to
      );
    } else {
      return;
    }
  };
  const handleClear = () => {
    resetFilter();
    getMyBookings();
  };
  // const dataSource = [
  //   {
  //     key: "1",
  //     eventName: "Abc Event",
  //     Price: 32,
  //     location: "10 Downing Street",
  //     date: "2-aug-2024",
  //     time: "10:20",
  //     id: "1",
  //   },
  //   {
  //     key: "2",
  //     eventName: "Xyz Event",
  //     Price: 42,
  //     location: "10 Downing Street",
  //     date: "2-aug-2024",
  //     time: "10:20",
  //     id: "2",
  //   },
  // ];

  const columns = [
    {
      title: "Image",
      dataIndex: ["event", "image  "],
      key: "image",
      render: (item) => (
        <Image
          preview={false}
          src={UPLOADS_URL + item}
          width={"48px"}
          height={"48px"}
          style={{ objectFit: "contain", borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Event Name",
      dataIndex: ["event", "title"],
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Location",
      dataIndex: ["event", "location", "address"],
      key: "location",
    },
    {
      title: "Date",
      dataIndex: ["event", "date"],
      key: "date",
      render: (item) => <span>{moment.utc(item).format("YYYY-MM-DD")}</span>,
    },
    {
      title: "Time",
      dataIndex: ["event", "time"],
      key: "time",
      render: (item) => <span>{formatTime(item)}</span>,
    },
    {
      title: "Action",
      dataIndex: "_action",
      key: "_id",
      render: (item, record) => (
        <FaEye
          style={{ fontSize: "16px", color: "#999999", cursor: "pointer" }}
          onClick={() =>
            navigate("/ticket-issue", {
              state: {
                eventDetails: record?.event,
                booking: record,
                qrCode: record?.qrCodeUrl,
              },
            })
          }
          size={"20px"}
        />
      ),
    },
  ];
  const handleFrom = (date) => {
    setFilter({
      ...filter,
      from: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      to: date,
    });
  };
  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p
          className="mainLabel"
          style={{ padding: "10px", fontSize: "18px", fontWeight: "bold" }}
        >
          Filter
        </p>
      </div>
      <hr style={{ marginBottom: 10 }} />

      <div className="filterDropdownBody">
        <p className="mainLabel">From:</p>
        <DatePicker
          className="mainInput filterInput web-input"
          value={filter.from}
          onChange={(e) => handleFrom(e)}
          style={{ width: "100%" }}
        />
        <p className="mainLabel">To:</p>
        <DatePicker
          className="mainInput filterInput web-input"
          value={filter.to}
          onChange={(e) => handleTo(e)}
          style={{ width: "100%" }}
        />

        <p className="mainLabel"> Status:</p>

        <Button
          type=""
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="purple-btn"
          //   onClick={() => getUsers()}
          onClick={handleApply}
        >
          Apply
        </Button>
        <Button
          type=""
          block
          size={"large"}
          className="purple-btn"
          onClick={handleClear}
        >
          Clear All
        </Button>
      </div>
    </div>
  );
  return (
    <div className="shop-page contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={22} md={22}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <h5 className="text-center">Tickets - Upcoming Events</h5>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: "10px 0" }}>
            <Col
              xs={23}
              md={23}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
              }}
            >
              <Popover
                content={filterContent}
                trigger="click"
                open={openTwo}
                onOpenChange={handleOpenChange}
                placement="bottomRight"
                arrow={false}
              >
                <Button
                  shape="circle"
                  style={{
                    padding: "12px 12px 5px",
                    height: "auto",
                    backgroundColor: "#7f00ff",
                  }}
                >
                  <FaFilter
                    style={{
                      fontSize: "16px",
                      color: "white",
                    }}
                  />
                </Button>
              </Popover>
              &emsp;
              <Input
                style={{ width: "250px" }}
                className="mainInput dashInput table-search"
                placeholder="Search Here"
                onChange={(e) => handleSearch(e.target.value)}
                suffix={
                  <FaSearch
                    style={{
                      color: "grey",
                      fontSize: 16,
                      cursor: "pointer",
                    }}
                    // onClick={() =>
                    //   getOrders(
                    //     1,
                    //     paginationConfig.limit,
                    //     filter.keyword
                    //   )
                    // }
                  />
                }
                // onPressEnter={(e) =>
                //   getOrders(
                //     1,
                //     paginationConfig.limit,
                //     filter.keyword
                //   )
                // }
              />
            </Col>
          </Row>

          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={22} lg={22}>
                  <div className="my-4">
                    <Row gutter={[16, 16]}>
                      <Col xs={0} md={10} lg={7} xl={6}>
                        <div className="left-div">
                          <ProfileSideBar />
                        </div>
                      </Col>

                      <Col xs={24} md={0} lg={0} xl={0}>
                        <>
                          <MdMenu
                            style={{ fontSize: 26, color: "#fff" }}
                            onClick={showDrawer}
                          />
                          <Drawer
                            placement="right"
                            onClose={onClose}
                            open={open}
                            className="fliterMenu"
                          >
                            <ProfileSideBar />
                          </Drawer>
                        </>
                      </Col>
                      <Col xs={24} md={14} lg={17} xl={18}>
                        <Table
                          dataSource={bookings}
                          columns={columns}
                          pagination={{ pageSize: 10 }}
                        />
                        ;
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileTickets;
