import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  Row,
  Form,
  Radio,
  Button,
  Space,
  Drawer,
  DatePicker,
  Slider,
  Pagination,
  Spin,
  Checkbox,
  Calendar,
  theme,
} from "antd";
import { useNavigate } from "react-router";
import { MdMenu } from "react-icons/md";
import EventCard from "../../../components/eventCard";
import AliceCarousel from "react-alice-carousel";
import { CATEGORY } from "../../../config/constants/api";
import { useDispatch, useSelector } from "react-redux";
import { Get } from "../../../config/api/get";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { getAllEvents } from "../../../redux/thunk/eventSlice";
// import { bookingDate } from "../../../components/Data/data";

const ServicePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const categoryId = location?.state?.categoryId;
  const locationData = location?.state?.locationData;
  const [address, setAddress] = useState("");
  const [showFollowedEvents, setShowFollowedEvents] = useState(false);
  const authToken = useSelector((state) => state.user.userToken);
  const keyword = useSelector((state) => state.keyword.keyword);
  const events = useSelector((state) => state.event?.data);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState([0, 10000]);
  useEffect(() => {
    getUniqueEvents();
  }, []);

  const { token } = theme.useToken();
  const wrapperStyle = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };
  const getUniqueEvents = () => {
    Get(CATEGORY.getCategories, authToken)
      .then((response) => {
        if (response?.status) {
          setCategories(response?.data?.docs);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching unique events");
      });
  };
  const handleSearch = () => {
    getEvents(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      typeState,
      range[0],
      range[1],
      from,
      to,
      showFollowedEvents
    );
  };
  const handleClear = () => {
    getEvents(
      paginationConfig.pageNumber,
      paginationConfig.limit,
    );
    setAddress("");
    if(typeState!==''){
      setTypeState("");
    }
  };
  const onRangeChange = (value) => {
    setRange(value);
    getEvents(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      typeState,
      value[0],
      value[1],
      from,
      to,
      showFollowedEvents
    );
  };
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const [typeState, setTypeState] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = events
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";

  // const getEvents = (
  //   pageNumber,
  //   pageSize,
  //   category,
  //   date,
  //   minPrice,
  //   maxPrice,
  // ) => {
  //   setLoading(true);
  //   Get(EVENT.getAllEvents, token, {
  //     page: pageNumber
  //       ? pageNumber.toString()
  //       : paginationConfig.pageNumber.toString(),
  //     limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
  //     category: category !== "All" ? category : null,
  //     date: date,
  //     minPrice,
  //     maxPrice,
  //     log:locationData?.longitude,
  //     lat:locationData?.latitude,
  //     keyword : keyword || undefined
  //   })
  //     .then((response) => {
  //       if (response?.status) {
  //         setEvents(response?.data?.docs);
  //         setPaginationConfig({
  //           pageNumber: response?.data?.page,
  //           limit: response?.data?.limit,
  //           totalDocs: response?.data?.totalDocs,
  //           totalPages: response?.data?.totalPages,
  //         });
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("Error fetching blogs ", err);
  //       setLoading(false);
  //     });
  // };
  const getEvents = async (
    pageNumber,
    pageSize,
    category,
    minPrice,
    maxPrice,
    from,
    to,
    followedEvents
  ) => {
    setLoading(true);
    const data = {
      token: authToken,
      otherData: {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: pageSize
          ? pageSize.toString()
          : paginationConfig.limit.toString(),
        category: category !== "All" ? category : null,
        minPrice,
        maxPrice,
        longitude: locationData?.longitude,
        latitude: locationData?.latitude,
        keyword: keyword || undefined,
        from,
        to,
        followedEvents,
      },
    };
    const response = await dispatch(getAllEvents(data)).unwrap();
    if (response) {
      setPaginationConfig({
        pageNumber: response?.page,
        limit: response?.limit,
        totalDocs: response?.totalDocs,
        totalPages: response?.totalPages,
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    // if (categoryId) {
    //   getEvents(
    //     paginationConfig?.pageNumber,
    //     paginationConfig?.limit,
    //     categoryId
    //   );
    //   setTypeState(categoryId);
    // } else {
    getEvents();
    // }
  }, []);
  const handleCategorySelect = (type) => {
    if (type === "All") {
      setTypeState(type);
      getEvents(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        null,
        range[0],
        range[1],
        from,
        to,
        showFollowedEvents
      );
    } else {
      getEvents(
        paginationConfig?.pageNumber,
        paginationConfig?.limit,
        type,
        range[0],
        range[1],
        from,
        to,
        showFollowedEvents
      );
      setTypeState(type);
    }
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getEvents(
      pageNumber,
      paginationConfig.limit,
      typeState,
      range[0],
      range[1],
      from,
      to,
      showFollowedEvents
    );
  };
  const responsive = {
    380: { items: 2 },
    568: { items: 2 },
    1024: { items: 3 },
    1200: { items: 4 },
    1400: { items: 4 },
    1500: { items: 5 },
  };
  {
  }
  // const items = useMemo(() => {
  //   if (!categories) return [];
  //   const allItem = (
  //     <div className="item" key="all">
  //       <div
  //         className="topCategeorySlider"
  //         onClick={() => handleCategorySelect("All")}
  //       >
  //         <span>All</span>
  //       </div>
  //     </div>
  //   );
  //   const categoryItems = categories.map((category) => (
  //     <div className="item" key={category._id}>
  //       <div
  //         className="topCategeorySlider"
  //         onClick={() => handleCategorySelect(category?._id)}
  //       >
  //         <span>{category?.name}</span>
  //       </div>
  //     </div>
  //   ));

  //   return [allItem, ...categoryItems];
  // }, [categories]);

  const [value, setValue] = useState(0);
  function onChange(e) {
    setValue(e.target.value);
    let date = new Date();
    let fromDate, toDate;
    if (showDatePicker) {
      setShowDatePicker(false);
    }
    switch (e.target.value) {
      case 1:
        fromDate = toDate = moment(date).format("YYYY-MM-DD");
        break;
      case 2:
        date.setDate(date.getDate() + 1);
        fromDate = toDate = moment(date).format("YYYY-MM-DD");
        break;
      case 3:
      case 7:
        let currentDay = date.getDay();
        // If today is already Saturday or Sunday, use this weekend
        if (currentDay === 6) {
          // Today is Saturday
          fromDate = toDate = moment(date).format("YYYY-MM-DD");
        } else if (currentDay === 0) {
          // Today is Sunday
          fromDate = toDate = moment(date)
            .subtract(1, "days")
            .format("YYYY-MM-DD"); // Yesterday was Saturday
        } else {
          // Calculate how many days until the upcoming Saturday and Sunday
          let saturday = new Date(date);
          saturday.setDate(date.getDate() + (6 - currentDay)); // Next Saturday
          let sunday = new Date(saturday);
          sunday.setDate(saturday.getDate() + 1); // Next Sunday

          fromDate = moment(saturday).format("YYYY-MM-DD");
          toDate = moment(sunday).format("YYYY-MM-DD");
        }
        break;
      case 5:
        fromDate = moment(date).format("YYYY-MM-DD"); // Use the current date
        toDate = moment(date).endOf("week").format("YYYY-MM-DD");
        break;
      case 6:
        fromDate = moment(date)
          .add(1, "weeks")
          .startOf("week")
          .format("YYYY-MM-DD");
        toDate = moment(date)
          .add(1, "weeks")
          .endOf("week")
          .format("YYYY-MM-DD");
        break;
      case 7:
        fromDate = moment(date).startOf("month").format("YYYY-MM-DD");
        toDate = moment(date).endOf("month").format("YYYY-MM-DD");
        break;
      case 8:
        fromDate = moment(date)
          .add(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        toDate = moment(date)
          .add(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;

      default:
      // handle default case
    }
    setFrom(fromDate);
    setTo(toDate);
    getEvents(
      paginationConfig?.pageNumber,
      paginationConfig?.limit,
      typeState,
      range[0],
      range[1],
      fromDate,
      toDate,
      showFollowedEvents
    );
  }
  // const onChange = (e) => {
  //   setValue(e.target.value);
  //   let date = new Date();
  //   if (e.target.value == 1) {
  //     date = moment(date).format("YYYY-MM-DD");
  //   } else {
  //     date.setDate(date.getDate() + 1);
  //     date = moment(date).format("YYYY-MM-DD");
  //   }
  //   setEventDate(date);
  //   getEvents(
  //     paginationConfig?.pageNumber,
  //     paginationConfig?.limit,
  //     typeState,
  //     date,
  //     range[0],
  //     range[1]
  //     // latitude,
  //     // longitude
  //   );
  // };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChangeDate = (date, dateString) => {
    let fromDate, toDate;
    fromDate = toDate = moment(date?.$d).format("YYYY-MM-DD");
    getEvents(
      paginationConfig?.pageNumber,
      paginationConfig?.limit,
      typeState,
      range[0],
      range[1],
      fromDate,
      toDate,
      showFollowedEvents
    );
    setFrom(fromDate);
    setTo(toDate);
  };
  const handleCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
    getEvents(
      paginationConfig?.pageNumber,
      paginationConfig?.limit,
      typeState,
      range[0],
      range[1],
      from,
      to,
      e.target.checked
    );
    setShowFollowedEvents(e.target.checked);
  };

  return (
    <div className="shop-page  contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={0} md={10} lg={7} xl={6}>
                <div className="left-div  mb-25">
                  <div className="filter-heading abc">
                    <p>Filter By Categories</p>
                  </div>
                  <div
                    className="filter-heading"
                    style={{
                      padding: "15px 0",
                    }}
                  >
                    {Array.isArray(categories) &&
                      categories.map((category, ind) => {
                        return (
                          <div
                            className={`tabNavigation ${
                              typeState === category?._id ? "active" : ""
                            }`}
                            key={category?._id}
                            onClick={() => handleCategorySelect(category?._id)}
                          >
                            {category?.name}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="left-div">
                  <div className="filter-heading abc">
                    <p>Other Filters</p>
                  </div>
                  <div className="inner-filter-box filter-canvas">
                    <Form layout="vertical">
                      <Form.Item
                        direction="vertical"
                        // label="Ticket Availability"
                      >
                        <Radio.Group onChange={onChange} value={value}>
                          <Radio value={1}>Today</Radio>
                          <Radio value={2}>Tomorrow</Radio>
                          <Radio value={3}>This Weekend</Radio>
                          <Radio
                            onClick={() => {
                              setShowDatePicker(!showDatePicker);
                            }}
                            value={4}
                          >
                            Pick a date
                          </Radio>
                          {showDatePicker && (
                            <div style={wrapperStyle}>
                              <Calendar
                                fullscreen={false}
                                onChange={onChangeDate}
                              />
                            </div>
                          )}
                          <Radio value={5}>This Week</Radio>
                          <Radio value={6}>Next Week</Radio>
                          <Radio value={7}>This Month</Radio>
                          <Radio value={8}>Next Month</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Checkbox
                        onChange={handleCheckbox}
                        className="text-white mb-4"
                      >
                        Only show events from organizers I follow
                      </Checkbox>

                      <Form.Item label="Price Range">
                        {/* <RangeSlider /> */}
                        <div>
                          <Slider
                            range
                            min={0}
                            max={10000}
                            step={100}
                            defaultValue={range}
                            onChange={onRangeChange}
                            trackStyle={[{ backgroundColor: "#8000FF" }]}
                            handleStyle={[
                              { borderColor: "#8000FF" },
                              { borderColor: "#8000FF" },
                            ]}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#fff",
                            }}
                          >
                            <span>${range[0]}</span>
                            <span>${range[1]}</span>
                          </div>
                        </div>
                      </Form.Item>

                      {/* <Form.Item label="Date">
                        <DatePicker
                          className="web-input2"
                          onChange={onChangeDate}
                        />
                      </Form.Item> */}

                      {/* <Form.Item label="LOCATION">
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleLocChange}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => {
                            return (
                              <div className="location-drop">
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Event By Location....",
                                    style: {
                                      marginBottom: "10px",
                                      height: "52px",
                                      borderRadius: "0px",
                                      border: "none",
                                      borderBottom: "2px solid white",
                                      background: "none",
                                      padding: "10px 50px 10px 10px",
                                    },
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active googleSuggestion"
                                      : "suggestion-item googleSuggestion";
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                          padding: "5px 10px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                          padding: "5px 10px",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        </PlacesAutocomplete>
                      </Form.Item> */}
                    </Form>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="apply-btn">
                        <Button
                          style={{
                            cursor: "pointer",
                          }}
                          className="mainbtn"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </div>

                      <div className="clear-btn">
                        <Button
                          style={{
                            cursor: "pointer",
                          }}
                          className="mainbtn"
                          onClick={handleClear}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} md={0} lg={0} xl={0}>
                <>
                  <MdMenu
                    style={{ fontSize: 26, color: "#fff" }}
                    onClick={showDrawer}
                  />
                  <Drawer
                    // title="Basic Drawer"
                    placement="right"
                    onClose={onClose}
                    open={open}
                    className="fliterMenu"
                  >
                    <div className="left-div mb-25">
                      <div className="filter-heading">
                        <p>Filter By Categories</p>
                      </div>
                      <div
                        className="filter-heading"
                        style={{
                          padding: "15px 0",
                        }}
                      >
                        {Array.isArray(categories) &&
                          categories.map((category, ind) => {
                            return (
                              <div
                                className={`tabNavigation ${
                                  typeState === category?._id ? "active" : ""
                                }`}
                                key={category?._id}
                                onClick={() =>
                                  handleCategorySelect(category?._id)
                                }
                              >
                                {category?.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="left-div">
                      <div className="filter-heading">
                        <p>Other Filters</p>
                      </div>
                      <div className="inner-filter-box filter-canvas">
                        <Form
                          layout="vertical"
                          name="basic"
                          initialValues={{
                            remember: true,
                          }}
                        >
                          <Form.Item
                            direction="vertical"
                            label="Ticket Availability"
                          >
                            <Radio.Group onChange={onChange} value={value}>
                              <Radio value={1}>Today</Radio>
                              <Radio value={2}>Tomorrow</Radio>
                              <Radio value={3}>This Weekend</Radio>
                              <Radio
                                onClick={() => {
                                  setShowDatePicker(!showDatePicker);
                                }}
                                value={4}
                              >
                                Pick a date
                              </Radio>
                              {showDatePicker && (
                                <div style={wrapperStyle}>
                                  <Calendar
                                    fullscreen={false}
                                    onChange={onChangeDate}
                                  />
                                </div>
                              )}
                              <Radio value={5}>This Week</Radio>
                              <Radio value={6}>Next Week</Radio>
                              <Radio value={7}>This Month</Radio>
                              <Radio value={8}>Next Month</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Checkbox
                            onChange={handleCheckbox}
                            className="text-white mb-4"
                          >
                            Only show events from organizers I follow
                          </Checkbox>

                          <Form.Item label="Price Range">
                            {/* <RangeSlider /> */}
                            <div>
                              <Slider
                                range
                                min={0}
                                max={10000}
                                step={100}
                                defaultValue={range}
                                onChange={onRangeChange}
                                trackStyle={[{ backgroundColor: "#8000FF" }]}
                                handleStyle={[
                                  { borderColor: "#8000FF" },
                                  { borderColor: "#8000FF" },
                                ]}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  color: "#fff",
                                }}
                              >
                                <span>${range[0]}</span>
                                <span>${range[1]}</span>
                              </div>
                            </div>
                          </Form.Item>

                          <Form.Item label="Date">
                            <DatePicker
                              className="web-input2"
                              onChange={onChangeDate}
                            />
                          </Form.Item>

                          {/* <Form.Item label="LOCATION">
                            <PlacesAutocomplete
                              value={address}
                              onChange={handleLocChange}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => {
                                return (
                                  <div className="location-drop">
                                    <input
                                      {...getInputProps({
                                        placeholder:
                                          "Search Event By Location....",
                                        style: {
                                          marginBottom: "10px",
                                          height: "52px",
                                          borderRadius: "100px",
                                          border: "1px solid #f4f4f4",
                                          backgroundColor: "#f4f4f4",
                                          padding: "10px 50px 10px 10px",
                                        },
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active googleSuggestion"
                                          : "suggestion-item googleSuggestion";
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                              padding: "5px 10px",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                              padding: "5px 10px",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }}
                            </PlacesAutocomplete>
                          </Form.Item> */}
                        </Form>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="apply-btn">
                            <Button
                              style={{
                                cursor: "pointer",
                              }}
                              className="mainbtn"
                              onClick={handleSearch}
                            >
                              Search
                            </Button>
                          </div>

                          <div className="clear-btn">
                            <Button
                              style={{
                                cursor: "pointer",
                              }}
                              className="mainbtn"
                              onClick={handleClear}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Drawer>
                </>
              </Col>

              <Col xs={24} md={14} lg={17} xl={18}>
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    {/* <CaregeorySlider /> */}
                    {/* <div className="categeorySlider">
                      <Row gutter={[16, 16]}>
                        <Col xs={24}>
                          <AliceCarousel
                            mouseTracking
                            items={items}
                            responsive={responsive}
                            disableButtonsControls={false}
                            disableDotsControls
                            controlsStrategy="alternate"
                            paddingLeft={0}
                            paddingRight={0}
                            infinite
                            autoPlay={false}
                          />
                        </Col>
                      </Row>
                    </div> */}
                    <div>
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{
                          width: "100%",
                        }}
                      >
                        {!loading ? (
                          <>
                            <Row gutter={[16, 0]}>
                              {Array.isArray(events) && events.length > 0 ? (
                                events.map((item, index) => {
                                  return (
                                    <Col xs={22} sm={12} md={24} lg={8}>
                                      <EventCard item={item} />{" "}
                                    </Col>
                                  );
                                })
                              ) : (
                                <p
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    margin: "250px auto",
                                    fontSize: "22px",
                                  }}
                                >
                                  No Events to Show
                                </p>
                              )}
                            </Row>
                            <Row style={{ padding: "10px 20px" }}>
                              <Col xs={24} md={12}>
                                <p style={{ color: "white" }}>{message}</p>
                              </Col>
                              <Col
                                xs={24}
                                md={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Pagination
                                  className="styledPagination"
                                  onChange={(e) => handlePageChange(e)}
                                  current={parseInt(
                                    paginationConfig?.pageNumber
                                  )}
                                  pageSize={paginationConfig?.limit}
                                  total={paginationConfig?.totalDocs}
                                  itemRender={itemRender}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <Row
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <Col
                              lg={19}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "150px 0px",
                              }}
                            >
                              <Spin />
                            </Col>
                          </Row>
                        )}
                      </Space>
                    </div>

                    {/* <Pagination defaultCurrent={1} total={50} /> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServicePage;
