import React from "react";
import { Col, Row, Card, Image, Button } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { UPLOADS_URL } from "../../config/constants/api";

function BlogCards({ item }) {
  return (
    <div className="home-page-slider">
      <Card
        className="blogCard wow fadeInRight"
        data-wow-duration="2.5s"
        data-wow-delay="0.8s"
        title={
          <>
            <Image
              preview={false}
              src={UPLOADS_URL + item?.image}
              style={{ height: "450px" , objectFit: "cover" , objectPosition: "center"}}
            />
          </>
        }
        bordered={false}
      >
        <h3>{item?.title}</h3>
        <p>{item?.description}</p>
        <Link to={`blogDetails/${item._id}`}>READ MORE...</Link>
      </Card>
    </div>
  );
}

export default BlogCards;
