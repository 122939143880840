import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Form,
  Select,
  DatePicker,
  Upload,
  message,
  Drawer 
} from "antd";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";

function ProfileSideBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? "tabNavigation active" : "tabNavigation";
  };

  return (
    <div
      className="filter-heading"
      style={{
        padding: "15px 0",
      }}
    >
      <div
        className={getActiveClass("/profile")}
        onClick={() => navigate("/profile")}
      >
        My Profile - Settings
      </div>
      <div
        className={getActiveClass("/wallet")}
        onClick={() => navigate("/wallet")}
      >
        Wallet
      </div>
      <div
        className={getActiveClass("/profileTickets")}
        onClick={() => navigate("/profileTickets")}
      >
        Tickets
      </div>
      <div
        className={getActiveClass("/profileEventHistory")}
        onClick={() => navigate("/profileEventHistory")}
      >
        Event History
      </div>
      <div
        className={getActiveClass("/profileOrganizers")}
        onClick={() => navigate("/profileOrganizers")}
      >
        Organizers
      </div>
    </div>
  );
}

export default ProfileSideBar;
