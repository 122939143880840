import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Image,
  Drawer,
  Table,
  Popover,
  Input,
} from "antd";
import { MdMenu } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ProfileSideBar from "../../../components/profileSidebar";
import OrganizerCard from "../../../components/organizerCard";
import dayjs from "dayjs";
import { extractTime } from "../../../config/helpers";
import { FaEye, FaFilter, FaSearch } from "react-icons/fa";
import { ORGANIZATION, UPLOADS_URL } from "../../../config/constants/api";
import { Get } from "../../../config/api/get";
import { useSelector } from "react-redux";

const ProfileOrganizers = () => {
  const navigate = useNavigate();
  const [openTwo, setOpenTwo] = useState(false);
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [organizations, setOrganizations] = useState(false);
  const [loading, setLoading] = useState(true);
  const [locationData, setLocationData] = useState(null);
  const location = useSelector((state) => state.location.location);
  const showDrawer = () => {
    setOpen(true);
  };
  const handleOpenChange = (newOpen) => {
    setOpenTwo(newOpen);
  };
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = organizations
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";
  const onClose = () => {
    setOpen(false);
  };
  const getOrganizations = (pageNumber, pageSize, keyword, log, lat) => {
    setLoading(true);
    Get(ORGANIZATION.getOrganizations, token, {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      keyword,
      log,
      lat,
    })
      .then((response) => {
        if (response?.status) {
          setOrganizations(response?.data?.docs);
          if (response?.data?.docs.length > 0) {
            setPaginationConfig({
              pageNumber: response?.data?.page,
              limit: response?.data?.limit,
              totalDocs: response?.data?.totalDocs,
              totalPages: response?.data?.totalPages,
            });
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getOrganizations();
  }, []);
  const handleClear = () => {
    setLocationData(null);
    getOrganizations();
  };
  const handleApply = () => {
    setLocationData(location);
    getOrganizations(
      paginationConfig?.pageNumber,
      paginationConfig?.limit,
      null,
      location?.longitude,
      location?.latitude
    );
  };
  // const dataSource = [
  //   {
  //     key: "1",
  //     eventName: "Abc Event",
  //     Price: 32,
  //     location: "10 Downing Street",
  //     date: "2-aug-2024",
  //     time: "10:20",
  //     id: "1",
  //   },
  //   {
  //     key: "2",
  //     eventName: "Xyz Event",
  //     Price: 42,
  //     location: "10 Downing Street",
  //     date: "2-aug-2024",
  //     time: "10:20",
  //     id: "2",
  //   },
  // ];

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (item) => (
        <Image
          preview={false}
          src={UPLOADS_URL + item}
          width={"48px"}
          height={"48px"}
          style={{ objectFit: "contain", borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Organization Name",
      dataIndex: "", // Since we're not using a direct dataIndex, we leave this empty
      key: "title",
      render: (item, record) => {
        return <span>{record.firstName + " " + record.lastName}</span>;
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Rating",
      dataIndex: "averageRating",
      key: "averageRating",
      render: (item) => {
        return <span>{item === null ? "0" : item}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (item, record) => (
        <FaEye
          style={{ fontSize: "16px", color: "#999999", cursor: "pointer" }}
          onClick={() =>
            navigate(`/profileOrganizers/${item}`, {
              state: { organization: record },
            })
          }
          size={"20px"}
        />
      ),
    },
  ];
  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p
          className="mainLabel"
          style={{ padding: "10px", fontSize: "18px", fontWeight: "bold" }}
        >
          Filter
        </p>
      </div>
      <hr style={{ marginBottom: 10 }} />

      <div className="filterDropdownBody">
        <p className="mainLabel"> Location:</p>

        <Input
          size={"large"}
          className="filterSelectBox"
          // placeholder="Select Status"
          value={
            locationData ? locationData?.city + " " + locationData?.state : ""
          }
          disabled={!locationData}
          style={{
            marginBottom: "10px",
          }}
        />

        <Button
          type=""
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="purple-btn"
          //   onClick={() => getUsers()}
          onClick={handleApply}
        >
          Apply Curent Location
        </Button>
        <Button
          type=""
          block
          size={"large"}
          className="purple-btn"
          onClick={handleClear}
          style={{ justifyContent: "center" }}
        >
          Clear
        </Button>
      </div>
    </div>
  );
  return (
    <div className="shop-page contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={22} md={22}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <h5 className="text-center">Organizers</h5>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: "10px 0" }}>
            <Col
              xs={23}
              md={23}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
              }}
            >
              <Popover
                content={filterContent}
                trigger="click"
                open={openTwo}
                onOpenChange={handleOpenChange}
                placement="bottomRight"
                arrow={false}
              >
                <Button
                  shape="circle"
                  style={{
                    padding: "12px 12px 5px",
                    height: "auto",
                    backgroundColor: "#7f00ff",
                  }}
                >
                  <FaFilter
                    style={{
                      fontSize: "16px",
                      color: "white",
                    }}
                  />
                </Button>
              </Popover>
              &emsp;
            </Col>
          </Row>

          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={22} lg={22}>
                  <div className="my-4">
                    <Row gutter={[16, 16]}>
                      <Col xs={0} md={10} lg={7} xl={6}>
                        <div className="left-div">
                          <ProfileSideBar />
                        </div>
                      </Col>

                      <Col xs={24} md={0} lg={0} xl={0}>
                        <>
                          <MdMenu
                            style={{ fontSize: 26, color: "#fff" }}
                            onClick={showDrawer}
                          />
                          <Drawer
                            placement="right"
                            onClose={onClose}
                            open={open}
                            className="fliterMenu"
                          >
                            <ProfileSideBar />
                          </Drawer>
                        </>
                      </Col>

                      <Col xs={24} md={14} lg={17} xl={18}>
                        <Table
                          dataSource={organizations}
                          columns={columns}
                          pagination={{ pageSize: 10 }}
                        />
                        ;
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileOrganizers;
