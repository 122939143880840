import React, { useEffect, useState } from "react";
import { Col, Image, Row, Spin, Button } from "antd";
import { ImageUrl } from "../../config/helper";
import {
  BsArrowDownRightCircleFill,
  BsArrowUpRightCircleFill,
} from "react-icons/bs";
import { Get } from "../../config/api/get";
import { CATEGORY, EVENT, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function AccordionTabs() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getUniqueEvents();
  }, []);
  const getUniqueEvents = () => {
    Get(CATEGORY.getCategories, token)
      .then((response) => {
        if (response?.status) {
          setCategories(response?.data?.docs);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching unique events");
        setLoading(false);
      });
  };
  return (
    <div>
      {!loading ? (
        <div
          className="container  wow fadeInDown"
          data-wow-duration="2.5s"
          data-wow-delay="0.8s"
        >
          {/* {Array.isArray(uniqueEvents) &&
            uniqueEvents.length &&
            uniqueEvents.map((event, index) => {
              return (
                <div className="card" key={index}>
                  <Image preview={false} src={UPLOADS_URL + event?.image} />

                  <div className="card__head">
                    <BsArrowDownRightCircleFill />
                    {event?.title}
                  </div>
                </div>
              );
            })} */}
          {Array.isArray(categories) &&
            categories.length &&
            categories.map((category, index) => {
              return (
                <div
                  className="card"
                  key={index}
                  onClick={() => {
                    navigate("/all-category", {
                      state: { categoryId: category?._id },
                    });
                  }}
                >
                  <Image preview={false} src={UPLOADS_URL + category?.image} />
                  <div className="card__head">
                    <BsArrowDownRightCircleFill />{" "}
                    {category?.name.toUpperCase()}
                  </div>
                </div>
              );
            })}
          {/* <div className="card">
            <Image preview={false} src={ImageUrl("navratri-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> NAVRATRI EVENT
            </div>
          </div>
          <div className="card">
            <Image preview={false} src={ImageUrl("comedy-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> COMEDY NIGHT
            </div>
          </div>
          <div className="card">
            <Image preview={false} src={ImageUrl("duserra-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> DUSSEHRA EVENT{" "}
            </div>
          </div>
          <div className="card">
            <Image preview={false} src={ImageUrl("holi-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> Holi Event
            </div>
          </div>
          <div className="card">
            <Image preview={false} src={ImageUrl("music-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> MUSIC EVENT
            </div>
          </div>
          <div className="card">
            <Image preview={false} src={ImageUrl("navratri-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> NAVRATRI EVENT
            </div>
          </div>
          <div className="card">
            <Image preview={false} src={ImageUrl("comedy-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> COMEDY NIGHT
            </div>
          </div>
          <div className="card">
            <Image preview={false} src={ImageUrl("duserra-event.png")} />
            <div className="card__head">
              <BsArrowDownRightCircleFill /> DUSSEHRA EVENT{" "}
            </div>
          </div> */}
        </div>
      ) : (
        <Row
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Col
            lg={19}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "150px 0px",
            }}
          >
            <Spin />
          </Col>
        </Row>
      )}
      <Row justify={"center"}>
        <Col
          xs={22}
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Button
            className="mainbtn"
            onClick={() => {
              navigate("/all-category", {
                state: { categoryId: 'All' },
              });
            }}
          >
            View More <BsArrowUpRightCircleFill />
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AccordionTabs;
