import { Button, Col, Row } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="contentarea inner-page">
        <Row justify={"center"}>
          <Col xs={22} lg={20}>
            <div
              className="entertainmentbottom shop-page-main wow fadeInDown"
              data-wow-duration="2.5s"
              data-wow-delay="1s"
              style={{ marginTop: "50px" }}
            >
              <h6>Exclusive events, priceless memories</h6>
              <h5>The Perfect Venue For Your Next Event</h5>
              <p>
                At Event Mela, we understand the challenges that come with
                organizing events. That’s why we offer a comprehensive suite of
                tools designed to simplify the process from start to finish.
                Whether you’re planning a small gathering or a large-scale
                festival, our platform allows you to create, manage, and promote
                your events with ease. With features such as customizable event
                pages, real-time analytics, and secure payment processing, you
                can focus on what matters most – delivering an unforgettable
                experience to your attendees.
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link
                  to="https://eventmela.com/organization"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mainbtn"
                >
                  Organize Event
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Index;
