import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm/index";
import { loadStripe } from "@stripe/stripe-js";
import { Get } from "../../config/api/get";
import { PAYMENT } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import payment from "./payment.css";
import PaymentMethods from "../../components/paymentMethods";

function Payment(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { quantity, eventDetails, total } = location?.state;
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const handleCardSelect = (paymentMethodId) => {
    setSelectedPaymentMethodId(paymentMethodId);
  };
  const token = useSelector((state) => state.user.userToken);
  useEffect(() => {
    if (!total) {
      navigate(-1);
    }
    Get(PAYMENT.paymentConfig, token)
      .then((response) => {
        const publishableKey = response.data?.publishableKey;
        setStripePromise(loadStripe(publishableKey));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  useEffect(() => {
    let body = {
      amount: total,
      currency: "USD",
    };
    Post(PAYMENT.paymentIntent, body, token)
      .then((response) => {
        const clientSecret = response.data?.clientSecret;
        setPaymentMethods(response.data?.paymentMethods?.data);
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
        <Col lg={24} style={{ textAlign: "center", padding: "20px 0" }}>
          <h1 style={{ color: "white" }}>CHECKOUT & PAYMENT</h1>
        </Col>
        <Col lg={13}>
          {Array.isArray(paymentMethods) && paymentMethods?.length > 0 && (
            <PaymentMethods
              paymentMethods={paymentMethods}
              onSelect={handleCardSelect}
            />
          )}
          <div className="payment-body">
            {clientSecret && stripePromise && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  amount={total}
                  quantity={quantity}
                  eventDetails={eventDetails}
                  selectedPaymentMethodId={selectedPaymentMethodId}
                  clientSecret={clientSecret}
                />
              </Elements>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Payment;
