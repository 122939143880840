import React, { useEffect, useState } from "react";
import { Col, Drawer, Row, Pagination, Spin, Input } from "antd";
import { MdMenu } from "react-icons/md";
import EventCard from "../../components/eventCard";
import { CATEGORY, EVENT } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { useDebouncedCallback } from "use-debounce";

const { Search } = Input;
function Category() {
  const [events, setEvents] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState(null);
  const categoryId = location?.state?.categoryId;
  const [typeState, setTypeState] = useState("");
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const token = useSelector((state) => state.user.userToken);
  const showDrawer = () => {
    setOpen(true);
  };
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = events
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = () => {
    Get(CATEGORY.getCategories, token)
      .then((response) => {
        if (response?.status) {
          setCategories(response?.data?.docs);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching unique events");
      });
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });
    getEvents(pageNumber, paginationConfig.limit, typeState);
  };
  const handleCategorySelect = (type) => {
    if (type === "All") {
      setTypeState(type);
      getEvents(paginationConfig?.pageNumber, paginationConfig?.limit, null);
    } else {
      getEvents(paginationConfig?.pageNumber, paginationConfig?.limit, type);
      setTypeState(type);
    }
  };
  const getEvents = (pageNumber, pageSize, category, keyword) => {
    setLoading(true);
    Get(EVENT.getAllEvents, token, {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: pageSize ? pageSize.toString() : paginationConfig.limit.toString(),
      category: category !== "All" ? category : null,
      keyword,
    })
      .then((response) => {
        if (response?.status) {
          setEvents(response?.data?.docs);
          setPaginationConfig({
            pageNumber: response?.data?.page,
            limit: response?.data?.limit,
            totalDocs: response?.data?.totalDocs,
            totalPages: response?.data?.totalPages,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  const handleSearch = useDebouncedCallback((e) => {
    getEvents(
      paginationConfig.pageNumber,
      paginationConfig.limit,
      typeState,
      e.target.value
    );
  }, 1000);
  useEffect(() => {
    if (!categoryId) {
      navigate(-1);
    }
    getEvents(
      paginationConfig?.pageNumber,
      paginationConfig?.limit,
      categoryId
    );
  }, []);
  return (
    <div className="contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={0} md={10} lg={7} xl={6}>
                <div className="left-div">
                  <h2
                    style={{
                      color: "white",
                      textAlign: "center",
                      padding: "15px 0px",
                      textShadow: "4px 7px 7px #000",
                    }}
                  >
                    Categories
                  </h2>
                  <div
                    className="filter-heading"
                    style={{
                      padding: "15px 0",
                    }}
                  >
                    {Array.isArray(categories) &&
                      categories.map((category, ind) => {
                        return (
                          <div
                            className={`tabNavigation ${
                              typeState === category?._id ? "active" : ""
                            }`}
                            key={category?._id}
                            onClick={() => handleCategorySelect(category?._id)}
                          >
                            {category?.name}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Col>

              <Col xs={24} md={0} lg={0} xl={0}>
                <>
                  <MdMenu
                    style={{ fontSize: 26, color: "#fff" }}
                    onClick={showDrawer}
                  />
                  <Drawer
                    // title="Basic Drawer"
                    placement="right"
                    onClose={onClose}
                    open={open}
                    className="fliterMenu"
                  >
                    <div className="left-div">
                      <h2
                        style={{
                          color: "white",
                          textAlign: "center",
                          padding: "15px 0px",
                          textShadow: "4px 7px 7px #000",
                        }}
                      >
                        Categories
                      </h2>
                      <div
                        className="filter-heading"
                        style={{
                          padding: "15px 0",
                        }}
                      >
                        {Array.isArray(categories) &&
                          categories.map((category, ind) => {
                            return (
                              <div
                                className={`tabNavigation ${
                                  typeState === category?._id ? "active" : ""
                                }`}
                                key={category?._id}
                                onClick={() =>
                                  handleCategorySelect(category?._id)
                                }
                              >
                                {category?.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </Drawer>
                </>
              </Col>

              <Col xs={24} md={14} lg={17} xl={18}>
                <Row justify={"end"}>
                  <Col xs={24} md={16} lg={8}>
                    <div
                      className="searchbar-top"
                      style={{ marginBottom: "15px" }}
                    >
                      <Search
                        // value={keyword}
                        placeholder="input search text"
                        prefix={<CiSearch />}
                        className="searchbar-top-first-searchfield"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col lg={24}>
                    {!loading ? (
                      <div className="tabContent">
                        <Row gutter={[16, 0]}>
                          {Array.isArray(events) && events.length > 0 ? (
                            events.map((item, index) => {
                              return (
                                <Col xs={22} sm={12} md={24} lg={8}>
                                  <EventCard item={item} />{" "}
                                </Col>
                              );
                            })
                          ) : (
                            <p
                              style={{
                                color: "white",
                                fontWeight: "bold",
                                margin: "250px auto",
                                fontSize: "22px",
                              }}
                            >
                              No Events to Show
                            </p>
                          )}
                        </Row>
                        <Row style={{ padding: "10px 20px" }}>
                          <Col xs={24} md={12}>
                            <p style={{ color: "white" }}>{message}</p>
                          </Col>
                          <Col
                            xs={24}
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Pagination
                              className="styledPagination"
                              onChange={(e) => handlePageChange(e)}
                              current={parseInt(paginationConfig?.pageNumber)}
                              pageSize={paginationConfig?.limit}
                              total={paginationConfig?.totalDocs}
                              itemRender={itemRender}
                            />
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Spin />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Category;
