import { createSlice } from "@reduxjs/toolkit";
import { getAllEvents } from "../thunk/eventSlice";

const eventSlice = createSlice({
  name: "event",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    // ----------------------------------- for get events api -----------------------------------------------
    builder.addCase(getAllEvents.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.docs;
    });
    builder.addCase(getAllEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});


export const eventReducers = eventSlice.reducer;
