import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "antd";
import { useParams } from "react-router-dom";
import { Get } from "../../config/api/get";
import { BLOG, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";

function AboutUs() {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.userToken);
  useEffect(() => {
    getBlogDetails();
  }, []);
  const getBlogDetails = () => {
    setLoading(true);
    Get(`${BLOG.getBlog}${id}`, token)
      .then((response) => {
        if (response?.status) {
          setBlogDetails(response?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching trending Events");
        setLoading(false);
      });
  };
  // console.log(blogDetails,'blogDetails')
  return (
    <div className="contentarea inner-page">
      <Row justify={"center"}>
        <Col xs={22} lg={20}>
          <h5
            className="text-center wow fadeInDown"
            data-wow-duration="2.5s"
            data-wow-delay="0.8s"
          >
            Blog Details
          </h5>
          <div
            className="entertainmentbottom mt-50 wow fadeInDown"
            data-wow-duration="2.5s"
            data-wow-delay="0.8s"
          >
            <Image
              preview={false}
              src={UPLOADS_URL + blogDetails?.image}
              style={{ height:"500px" , width : '500px' }}
            />
            <h5>{blogDetails?.title}</h5>

            <p>{blogDetails?.description}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AboutUs;
