import { useEffect } from "react";
import React, { useState } from "react";
import { Col, Row, Button, Form, Input, Drawer } from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { MdMenu } from "react-icons/md";
import { PAYMENT, UPLOADS_URL } from "../../../config/constants/api";
import { Link } from "react-router-dom";
import ProfileSideBar from "../../../components/profileSidebar";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import {
  FaSquareSnapchat,
  FaSquareXTwitter,
  FaSquareThreads,
} from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";
// import DashbordSidebar from "../../../components/DashboardSidebar";
// import { myprofiledata } from "../../../components/Data/data";
import { extractDate } from "../../../config/helpers/index";
import { ImageUrl } from "../../../config/helper";
import { Get } from "../../../config/api/get";

const DropzoneFiltercards = () => {
  const [form] = Form.useForm();
  const userData = useSelector((state) => state?.user?.userData);
  const token = useSelector((state) => state?.user?.userToken);
  const navigate = useNavigate();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const getClientPaymentMetods = async () => {
    Get(PAYMENT.getClientPaymentMetods, token)
      .then((response) => {
        if (response?.status) {
          setPaymentMethods(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching payment methods");
      });
  };
  useEffect(() => {
    getClientPaymentMetods();
  }, []);
  const onClose = () => {
    setOpen(false);
  };
  const onFinish = () => {};
  const onFinishFailed = () => {};
  return (
    <div className="shop-page contentarea inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={22} md={22}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <h5 className="text-center">Credit Card Information</h5>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={22} lg={22}>
                  <div className="my-4">
                    <Row gutter={[16, 16]}>
                      <Col xs={0} md={10} lg={7} xl={6}>
                        <div className="left-div">
                          <ProfileSideBar />
                        </div>
                      </Col>

                      <Col xs={24} md={0} lg={0} xl={0}>
                        <>
                          <MdMenu
                            style={{ fontSize: 26, color: "#fff" }}
                            onClick={showDrawer}
                          />
                          <Drawer
                            placement="right"
                            onClose={onClose}
                            open={open}
                            className="fliterMenu"
                          >
                            <ProfileSideBar />
                          </Drawer>
                        </>
                      </Col>
                      <Col lg={12}>
                        <div style={{ color: "#fff", marginLeft: "20px" }}>
                          {Array.isArray(paymentMethods) &&
                          paymentMethods.length > 0 ? (
                            <div
                              className="card-list"
                              style={{ marginTop: "20px" }}
                            >
                              <h2 style={{paddingBottom:'10px'}}>Your Existing Cards Info</h2>
                              {paymentMethods.map((method) => (
                                <div
                                  key={method.id}
                                  className="card-item"
                                  style={{
                                    border: "2px solid #fff",
                                    padding: "10px",
                                    borderRadius: "12px",
                                    width: "100%",
                                    fontWeight: "bold",
                                    boxShadow: "1px 2px 2px #fff",
                                  }}
                                >
                                  <div className="card-brand">
                                    Brand : {method.card.brand.toUpperCase()}
                                  </div>
                                  <div className="card-last4">
                                    Card Number : **** **** ****{" "}
                                    {method.card.last4}
                                  </div>
                                  <div className="card-expiry">
                                    Expiration Date : {method.card.exp_month}/
                                    {method.card.exp_year}
                                  </div>
                                  <div className="card-brand">
                                    Country : {method.card.country}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <h2 style={{textAlign:'center'}}>No Payment Methods Info to show</h2>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
