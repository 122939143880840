// export const BASE_URL = "https://secure.demo243.webhostlabs.net:3002/api/"
// export const UPLOADS_URL = "https://secure.demo243.webhostlabs.net:3002/Uploads/"
const { NODE_ENV } = process.env;
const { hostname } = window.location;

const servers = {
  local: "http://localhost:3024",
  customDev: "https://react.customdev.solutions:3024",
  live: "https://api.eventmela.com/",
};

var URL;
if (NODE_ENV === "production" && hostname === "react.customdev.solutions")
  URL = servers.customDev;
else if (NODE_ENV === "production" && hostname.includes("eventmela.com"))
  URL = servers.customDev;
else URL = servers.local;

export const SOCKET_URL = `${URL}`;

export const UPLOADS_URL = URL + "/Uploads/";
export const BASE_URL = URL + "/api";

// import ChangePassword from "../../views/change-password"

// export const BASE_URL = "http://localhost:3002/api"
// export const UPLOADS_URL = "http://localhost:3002/Uploads"
export const USER_AUTH = {
  login: "/user/login",
  signup: "/user/signup",
};

export const NEWSLETTER = {
  signup: "/user/newsletterSignup",
};
export const PROFILE = {
  editProfile: "/user/editProfile",
};
export const AUTH = {
  signin: "/auth/signin",
  signup: "/auth/signup",
  emailCode: "/auth/emailVerificationCode",
  verifyCode: "/auth/verifyRecoverCode",
  resetPassword: "/auth/resetPassword",
};
export const BLOG = {
  getAllBlogs: "/blog/getAllBlogs",
  getBlog: "/blog/getBlog/",
  addBlog: "/blog/addBlog",
};
export const EVENT = {
  addEvent: "/event/addEvent",
  getAllEvents: "/event/getAllEvents",
  getEvent: "/event/getEvent/",
  getTrendingEvents: "/event/getTrendingEvents",
  getUniqueEvents: "/event/getUniqueEvents",
  getOrganizationEvents: "/event/getOrganizationEvents/",
};
export const CATEGORY = {
  getCategories: "/category/getCategories",
};
export const FEEDBACK = {
  addFeedback: "/feedback/addFeedback",
};
export const STUDENT = {
  updateProfile: "/profile/updateProfile",
  changePassword: "/profile/changePassword",
  getMyCoaches: "/profile/getMyCoaches",
};

export const USERS = {
  get: "/auth/signin",
  getAllCoaches: "/admin/user/getAllCoaches",
  getAllTutors: "/admin/user/getAllTutors",
  getCoachById: "/admin/user/getCoachById/",
  followOrganization: "/user/followOrganization/",
  unfollowOrganization: "/user/unfollowOrganization/",
  verifyEmail: "/user/verifyEmail",
  getUser: "/user/getUser/",
  verifyEmailVerificationCode: "/user/verifyEmailVerificationCode/",
};

export const COACH = {
  updateProfile: "/coach/profile/updateProfile",
  changePassword: "/coach/profile/changePassword",
};

export const SERVICES = {
  getAll: "/service/getAllServices",
};

export const RATES = {
  getMyRates: "/rates/getMyRates",
  setRates: "/rates/setRates",
};

export const COMISSSION = {
  getComission: "/comission/getComission",
};

export const SCHEDULE = {
  addSchedule: "/schedule/addSchedule",
  getMySchedule: "/schedule/getMySchedule",
  getScheduleByCoachId: "/schedule/getScheduleByCoachId/",
};

export const REVIEWS = {
  getEventReviews: "/review/getEventReviews/",
  addReview: "/review/addReview",
};

export const LESSON = {
  bookLesson: "/lesson/addLesson",
  getUpcomingLessons: "/lesson/getAllUpcomingLessons",
  getCompletedLessons: "/lesson/getAllCompletedLessons",
  getLiveLessons: "/lesson/getAllLiveLessons",
  getLessonById: "/lesson/getLessonById/",
};

export const PAYMENT = {
  paymentConfig: "/payment/config",
  paymentIntent: "/payment/create-payment-intent",
  stripeCharge: "/payment/create-stripe-charge",
  savePayment: "/payment/savePayment",
  getClientPaymentMetods : "/payment/getClientPaymentMetods"
};

export const BOOKING = {
  bookEventTicket: "/booking/bookEventTicket",
  getMyAllBookings: "/booking/getMyAllBookings",
};
export const RESET = {
  sendCode: "/reset/sendVerificationCode",
  verifyCode: "/reset/verifyRecoverCode",
  resetPassword: "/reset/resetPassword",
  changePassword: "/reset/changePassword",
};
export const ORGANIZATION = {
  getOrganizations: "/organization/getOrganizations",
};
