import React from "react";

const PaymentMethods = ({ paymentMethods, onSelect }) => {
  return (
    <div style={{ color: "#fff" , marginLeft:'20px' }}>
      <h2>Make Payment Using Saved Card</h2>
      <div className="card-list" style={{ marginTop: "20px"  }}>
        {paymentMethods.map((method) => (
          <div
            key={method.id}
            className="card-item"
            onClick={() => onSelect(method.id)}
            style={{
              cursor: "pointer",
              border: "2px solid #fff",
              padding: "10px",
              borderRadius:'12px',
              width:'80%',
              fontWeight:'bold',
              boxShadow:'1px 2px 2px #fff'

            }}
          >
            <div className="card-brand">Brand : {method.card.brand.toUpperCase()}</div>
            <div className="card-last4">Card Number : **** **** **** {method.card.last4}</div>
            <div className="card-expiry">
              Expiration Date :  {method.card.exp_month}/{method.card.exp_year}
            </div>
            <div className="card-brand">Country : {method.card.country}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;
