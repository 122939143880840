import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import Banner from "../../components/banner";
import AccordionTabs from "../../components/accordion";
import MarqueeText from "../../components/marquee";
import SocialEntertainment from "../../components/socialEntertainment";
import Trending from "../../components/trending";
import Testimonials from "../../components/testimonials";
import GetTickets from "../../components/getTickets";
import BlogCards from "../../components/blogCard";
import { BLOG } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useSelector } from "react-redux";
import { ImageUrl } from "../../config/helper";
import HomeComponent4 from "../../components/homepageComponent";
import AliceCarousel from "react-alice-carousel";

const responsive = {
  380: { items: 1 },
  568: { items: 1 },
  1024: { items: 2 },
  1200: { items: 3 },
  1400: { items: 3 },
  1500: { items: 3 },
};

function Home() {
  const token = useSelector((state) => state.user.userToken);
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const getBlogs = (pageNumber, pageSize) => {
    setLoading(true);
    Get(BLOG.getAllBlogs, token, {
      page: "1",
      limit: "4",
    })
      .then((response) => {
        if (response?.status) {
          setBlogs(response?.data?.docs);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching blogs ", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getBlogs();
  }, []);
  const [blogCard, SetBlogCard] = useState([
    {
      key: 1,
      image: ImageUrl("blog-1.png"),
      title: "6 Strategies  Your Conference Keynote ",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },

    {
      key: 2,
      image: ImageUrl("blog-2.png"),
      title: "How successfully used paid ticket sales",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },
    {
      key: 3,
      image: ImageUrl("blog-3.png"),
      title: "Introducing work with new navigation",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },
    {
      key: 4,
      image: ImageUrl("blog-4.png"),
      title: "6 Strategies  Your Conference Keynote",
      para: "Lorem Ipsum is simply dummy text of the printing typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since... ",
      link: "READ MORE...",
    },
  ]);
  return (
    <div>
      <Banner />
      <MarqueeText />
      <div className="accordion">
        <AccordionTabs />
      </div>
      <div className="contentarea">
        {/* <SocialEntertainment /> */}
       
        <Trending />
        <HomeComponent4 />
        {/* <Testimonials /> */}
        {/* <Tesimonials/> */}
        <div className="blog">
          <Row justify={"center"}>
            <Col xs={22} lg={20}>
              <h3>Be sure not to miss these event today.</h3>
              <h2>LIME LIGHT</h2>

              {blogs && (
                <AliceCarousel
                  mouseTracking
                  responsive={responsive}
                  disableButtonsControls
                  disableDotsControls
                  controlsStrategy="alternate"
                  paddingLeft={0}
                  paddingRight={0}
                  infinite
                  // autoPlay
                  autoPlayInterval={2000}
                  animationDuration={1500}
                >
                  {blogs.map((item, index) => (
                    <div className="">
                      <BlogCards item={item} />
                    </div>
                  ))}
                </AliceCarousel>
              )}
            </Col>
          </Row>
        </div>
        <GetTickets />
      </div>
    </div>
  );
}

export default Home;
