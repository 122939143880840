import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ClientLayout from "../../components/layout";
import Home from "../../views/home";
import AboutUs from "../../views/about";
import BlogsPage from "../../views/blogs";
import BlogDetails from "../../views/blogs/blogDetails";
import Login from "../../views/login";
import CreateAccount from "../../views/createAccount";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import ContactUs from "../../views/contactus";
import Events from "../../views/events";
import TicketsDetails from "../../views/ticketsDetails";
import OrderDetails from "../../views/orderDetails";
import TicketIssue from "../../views/ticketIssue";
import Payment from "../../views/Payment";
import ProtectedRoute from "../../views/protectedRoute";
import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import ProfileTickets from "../../views/profile/profileTickets";
import ProfileEventHistory from "../../views/profile/profileEventHistory";
import ProfileOrganizers from "../../views/profile/profileOrganizers";
import ProfileOrganizerDetails from "../../views/profile/profileOrganizers/profileOrganizerDetails";
import Wallet from "../../views/profile/wallet"

import Organize from "../../views/organize/index";
import Category from "../../views/category";
import axios from "axios";
import { setSavedLocation } from "../../redux/slice/locationSlice";
import { setLocation } from "../../redux/slice/locationSlice";
import { useDispatch, useSelector } from "react-redux";

function MyRouter() {
  const dispatch = useDispatch();
  // const apiKey = "AIzaSyDT2godi8Nl6w43aBXUYkVDpzDdoSgtwJs";
  const apiKey = "AIzaSyBXqV9bSEkfm5Wh7OQMj37V-n3F4AiyE40";
  // const location = useLocation()
  

  const [locationData, setLocationData] = useState(
    JSON.parse(localStorage.getItem("locationData"))
  );
  useEffect(() => {
    if (!locationData || locationData===null) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        fetchLocation(latitude, longitude);
      });
    }
  }, []);

  const fetchLocation = async (lat, lon) => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
    );
    if (response?.data && response?.data?.results.length > 0) {
      const addressComponents = response.data.results[0].address_components;
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const state = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.long_name;
      if (city && state) {
        const locationData = { city, state, latitude: lat, longitude: lon };
        dispatch(setSavedLocation(locationData)) 
        dispatch(setLocation(locationData)) 
        // localStorage.setItem("locationData", JSON.stringify(locationData));
      }
    }
  };
  return (
    <>
      {/* <BrowserRouter basename='/badshah_tickets'> */}
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <ClientLayout header={true}>
                <Home />
              </ClientLayout>
            }
          />
          <Route
            path="/organize"
            index
            element={
              <ClientLayout header={true}>
                <Organize />
              </ClientLayout>
            }
          />
          <Route
            path="/profile"
            index
            element={
              <ProtectedRoute>
                <ClientLayout
                  header={true}
                  head={{
                    title: "Dashboard",
                    description: "Some Description.",
                  }}
                  headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                  <Profile />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/wallet"
            index
            element={
              <ProtectedRoute>
                <ClientLayout
                  header={true}
                  head={{
                    title: "Dashboard",
                    description: "Some Description.",
                  }}
                  headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                  <Wallet />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/editProfile"
            index
            element={
              <ProtectedRoute>
                <ClientLayout
                  header={true}
                  head={{
                    title: "Dashboard",
                    description: "Some Description.",
                  }}
                  headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                  <EditProfile />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/profileTickets"
            index
            element={
              // <ProtectedRoute>
              <ClientLayout
                header={true}
                head={{
                  title: "Dashboard",
                  description: "Some Description.",
                }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ProfileTickets />
              </ClientLayout>
              // </ProtectedRoute>
            }
          />
          <Route
            path="/profileOrganizers"
            index
            element={
              // <ProtectedRoute>
              <ClientLayout
                header={true}
                head={{
                  title: "Dashboard",
                  description: "Some Description.",
                }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ProfileOrganizers />
              </ClientLayout>
              // </ProtectedRoute>
            }
          />
          <Route
            path="/profileOrganizers/:id"
            index
            element={
              // <ProtectedRoute>
              <ClientLayout
                header={true}
                head={{
                  title: "Dashboard",
                  description: "Some Description.",
                }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ProfileOrganizerDetails />
              </ClientLayout>
              // </ProtectedRoute>
            }
          />
          <Route
            path="/profileEventHistory"
            index
            element={
              // <ProtectedRoute>
              <ClientLayout
                header={true}
                head={{
                  title: "Dashboard",
                  description: "Some Description.",
                }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ProfileEventHistory />
              </ClientLayout>
              // </ProtectedRoute>
            }
          />
          <Route
            path="/changePassword"
            index
            element={
              <ProtectedRoute>
                <ClientLayout
                  header={true}
                  head={{
                    title: "Dashboard",
                    description: "Some Description.",
                  }}
                  headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                  <ChangePassword />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/about"
            index
            element={
              <ClientLayout header={true}>
                <AboutUs />
              </ClientLayout>
            }
          />
          <Route
            path="/blog"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <BlogsPage />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/blogDetails/:id"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <BlogDetails />
                </ClientLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/createAccount"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <CreateAccount />
              </ClientLayout>
            }
          />
          <Route
            path="/login"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <Login />
              </ClientLayout>
            }
          />
          <Route
            path="/forget-password"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <ForgetPassword1 />
              </ClientLayout>
            }
          />
          <Route
            path="/enter-email"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <ForgetPassword2 />
              </ClientLayout>
            }
          />
          <Route
            path="/code"
            index
            element={
              <ClientLayout header={false} footer={false}>
                <ForgetPassword3 />
              </ClientLayout>
            }
          />

          <Route
            path="/contactus"
            index
            element={
              <ClientLayout header={true}>
                <ContactUs />
              </ClientLayout>
            }
          />
          <Route
            path="/events"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <Events />
                </ClientLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/event-details/:id"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <TicketsDetails />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/order_details/:id"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <OrderDetails />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/ticket-issue"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <TicketIssue />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/all-category"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <Category />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment"
            index
            element={
              <ProtectedRoute>
                <ClientLayout
                  head={{
                    title: "Dashboard",
                    description: "Some Description.",
                  }}
                  headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                  <Payment />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MyRouter;
