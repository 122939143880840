import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    location : {},
    savedLocation:{}
}

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
      setLocation: (state, { payload }) => {
       state.location = payload
      },
      setSavedLocation: (state, { payload }) => {
       state.savedLocation = payload
      },
    },
  });
  
  export const { setLocation, setSavedLocation } = locationSlice.actions
  
  export default locationSlice.reducer